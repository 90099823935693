<template>
    <footer class="footer">
        <div class="container">
            <div class="footer-contents">
                <img class="logo" src="~/assets/images/svg/logo.svg" alt="">
                <div class="footer-top">
                    <div class="top-left">
                        <div class="left-left">
                            <div class="link-group">
                                <span class="link-group-title">{{ $t('footer.for_buyers') }}</span>
                                <div class="small-divider"></div>
                                <div class="link-group-links">
                                    <NuxtLinkLocale to="/faq?open_tab=customers">{{ $t('footer.how_to_choose') }}</NuxtLinkLocale>
                                    <NuxtLinkLocale to="/feedback">{{ $t('footer.help_with_service') }}</NuxtLinkLocale>
                                </div>
                            </div>
                            <div class="link-group">
                                <span class="link-group-title">{{ $t('footer.about') }}</span>
                                <div class="small-divider"></div>
                                <div class="link-group-links">
                                    <NuxtLinkLocale to="/about">{{ $t('footer.about_project') }}</NuxtLinkLocale>
                                    <NuxtLinkLocale to="/rules">{{ $t('footer.rules') }}</NuxtLinkLocale>
                                    <NuxtLinkLocale to="/blog">{{ $t('footer.blog') }}</NuxtLinkLocale>
                                </div>
                            </div>
                        </div>
                        <div class="left-right">
                            <div class="link-group">
                                <span class="link-group-title">{{ $t('footer.for_sellers') }}</span>
                                <div class="small-divider"></div>
                                <div class="link-group-links">
                                    <NuxtLinkLocale to="/faq?open_tab=sellers">{{ $t('footer.earn_on_your_products') }}</NuxtLinkLocale>
                                    <NuxtLinkLocale to="/store-create" class="icon-star">{{ $t('footer.create_store') }}</NuxtLinkLocale>
                                </div>
                            </div>
                            <div class="link-group">
                                <span class="link-group-title">{{ $t('footer.documents') }}</span>
                                <div class="small-divider"></div>
                                <div class="link-group-links">
                                    <a :href="`/${locale}/terms-of-use.pdf`" target="_blank">{{ $t('footer.terms_of_use') }}</a>
                                    <a :href="`/${locale}/privacy-cookie-policy.pdf`" target="_blank">{{ $t('footer.privacy_cookie_policy') }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="top-right">
                        <div class="socials">
                            <span class="socials-title">{{ $t('footer.social_networks') }}</span>
                            <div class="small-divider social-divider"></div>
                            <div class="socials-container">
                                <NuxtLink v-for="social in socialsArr" :key="social.img" :to="social.slug" external target="_blank" class="social-item">
                                    <img :src="social.img" :width="social.width" :height="social.height" class="social-img" />
                                    <img v-if="social.country.length > 0" width="18px" height="18px" class="social-country" :src="social.country" />
                                </NuxtLink>
                            </div>
                        </div>
                        <div class="payments">
                            <span class="socials-title">{{ $t('footer.payment_methods') }}</span>
                            <div class="small-divider social-divider"></div>
                            <div class="payments-container">
                                <div v-for="payment in paymentsArr" :key="payment.img" class="social-item" :style="{ 'background-color': payment.bgColor }">
                                    <img :src="payment.img" :width="payment.width" :height="payment.height" />
                                </div>
                                <div class="empty-box"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="large-divider"></div>
                <div class="footer-bottom">
                    <span>© 2024—{{ new Date().getFullYear() }}, Gstock</span>
                    {{ $t('footer.requisites') }}
                </div>
            </div>
        </div>
        <img width="465px" class="footer-img" src="~/assets/images/png/footer/digital-marketplace1@1x.png">
    </footer>
</template>
<script lang='ts' setup>
const { locale } = useI18n()
const socialsArr = [
    {
        img: useAsset('/assets/images/svg/socials/tg.svg'),
        country: useAsset('/assets/images/png/footer/usa.png'),
        width: '30px',
        height: '25px',
        slug: "https://t.me/gstock_news_en"
    },
    {
        img: useAsset('/assets/images/svg/socials/tg.svg'),
        country: useAsset('/assets/images/png/footer/rus.png'),
        width: '30px',
        height: '25px',
        slug: "https://t.me/gstock_news"
    },
    /* {
        img: useAsset('/assets/images/svg/socials/tg.svg'),
        country: useAsset('/assets/images/png/footer/chin.png'),
        width: '30px',
        height: '25px'
    }, */
    /* {
        img: useAsset('/assets/images/svg/socials/yt.svg'),
        country: '',
        width: '29px',
        height: '20px'
    }, */
    {
        img: useAsset('/assets/images/svg/socials/vk.svg'),
        country: '',
        width: '31px',
        height: '20px',
        slug: "https://vk.com/gstockorg"
    },
    /* {
        img: useAsset('/assets/images/svg/socials/tt.svg'),
        country: '',
        width: '26px',
        height: '30px'
    },
    {
        img: useAsset('/assets/images/svg/socials/ds.svg'),
        country: '',
        width: '30px',
        height: '22px'
    }, */
]

const paymentsArr = [
    {
        img: useAsset('/assets/images/svg/payments/visa.svg'),
        bgColor: '#FFFFFF',
        width: "41px",
        height: "41px"
    },
    {
        img: useAsset('/assets/images/svg/payments/master-card.svg'),
        bgColor: '#FFFFFF',
        width: "37px",
        height: "23px"
    },
    {
        img: useAsset('/assets/images/svg/payments/mir.svg'),
        bgColor: '#FFFFFF',
        width: "39px",
        height: "11px"
    },
    {
        img: useAsset('/assets/images/svg/payments/t.svg'),
        bgColor: '#FFFFFF',
        width: "39px",
        height: "34px"
    },
    {
        img: useAsset('/assets/images/svg/payments/btc.svg'),
        bgColor: '#F7931A',
        width: "28px",
        height: "35px"
    },
    {
        img: useAsset('/assets/images/svg/payments/safari.svg'),
        bgColor: '#627EEA',
        width: "26px",
        height: "41px"
    },
    {
        img: useAsset('/assets/images/svg/payments/cube.svg'),
        bgColor: '#FFFFFF',
        width: "37px",
        height: "37px"
    },
    {
        img: useAsset('/assets/images/svg/payments/payeer.svg'),
        bgColor: '#FFFFFF',
        width: "43px",
        height: "9px"
    },
    {
        img: useAsset('/assets/images/svg/payments/chair.svg'),
        bgColor: 'linear-gradient(180deg, #EF3F51 0%, #FC6743 100%)',
        width: "26px",
        height: "22px"
    },

]

const {t} = useI18n()

useSchemaOrg({
    "@type": 'Organization',
    "logo": 'https://gstock.org/_nuxt/logo.DiFd60Xw.svg',
    "name": 'Gstock',
    "address": t('footer.address')
})
</script>

<style lang="scss" scoped>
.footer {
    width: 100%;
    border-top-left-radius: 64px;
    border-top-right-radius: 64px;
    background-color: #090630;
    margin-top: 94px;
    color: white;

    @media (max-width:1023px) {
        border-top-left-radius: 32px;
        border-top-right-radius: 32px;
    }
}

.footer .logo {
    display: none;

    @media (max-width: 1023px) {
        display: block;
        width: 133px;
        margin: 0 auto 16px;
    }
}

.footer-img {
    position: absolute;
    bottom: 0;
    right: 0;
    object-fit: cover;

    @media (max-width: 1460px) {
        width: 280px;
    }

    @media (max-width: 1023px) {
        width: 176px;
    }
}

.top-right {
    display: flex;
    flex-direction: column;
    gap: 38px;

    @media (max-width: 1023px) {
        flex-direction: column;
        gap: 14px;
    }
}

.socials,
.payments {
    .social-divider {
        margin-bottom: 18px;

        @media (max-width: 1023px) {
            margin-bottom: 10px;
        }
    }
}

.link-group-title,
.socials-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 19.2px;

    @media (max-width: 1023px) {
        font-size: 20px;
        line-height: 80%;
    }
}

.footer-top {
    display: flex;
    gap: 144px;

    @media (max-width: 1460px) {
        gap: 130px;
    }

    @media (max-width: 1023px) {
        flex-direction: column;
        gap: 14px;
    }

}

.footer-bottom {
    display: flex;
    gap: 0 32px;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: -0.03em;
    color: #fff;
    opacity: 0.8;

    @media (max-width: 2160px) {
        max-width: calc(100% - 180px);
    }

    @media (max-width: 1800px) {
        max-width: calc(100% - 360px);
    }

    @media (max-width: 1460px) {
        max-width: calc(100% - 240px);
    }

    @media (max-width: 1023px) {
        font-size: 15px;
        flex-direction: column;
        max-width: calc(100% - 150px);
    }
}

.top-left {
    display: flex;
    gap: 75px;

    @media (max-width: 1023px) {
        flex-direction: column;
        gap: 14px;
    }
}

.left-left,
.left-right {
    display: flex;
    flex-direction: column;
    gap: 44px;

    @media (max-width: 1023px) {
        gap: 14px;
    }
}

.link-group {
    display: flex;
    flex-direction: column;
}

.link-group-links {
    display: flex;
    flex-direction: column;
    gap: 3px;

    @media (max-width: 1023px) {
        gap: 1px;
    }

    a {
        display: flex;
        align-items: center;
        gap: 8px;
        text-decoration: none;
        color: rgba(255, 255, 255, 0.8);
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        transition: color .3s ease;

        &.icon-star {
            &::before {
                content: url('/assets/images/svg/star-2.svg');
            }
        }

        &:hover {
            color: #da4727;
        }

        @media (max-width: 1023px) {
            font-size: 16px;
            line-height: 130%;
        }
    }
}

.small-divider {
    width: 132px;
    margin-top: 18px;
    margin-bottom: 11px;
    height: 1px;
    opacity: 25%;
    background-color: white;

    @media (max-width: 1023px) {
        width: 100%;
        margin-top: 8px;
        margin-bottom: 8px;
    }
}

.large-divider {
    width: 100%;
    margin-top: 18px;
    margin-bottom: 18px;
    height: 1px;
    opacity: 25%;
    background-color: white;

    @media (max-width: 1023px) {
        margin-top: 12px;
        margin-bottom: 12px;
    }
}

.socials,
.payments {
    display: flex;
    flex-direction: column;
}

.footer-contents {
    display: flex;
    flex-direction: column;
    padding: 63px 24px 15px;

    @media (max-width:1023px) {
        padding: 24px 8px 10px;
    }
}

.socials-container,
.payments-container {
    display: grid;
    /* grid-auto-columns: 53px;
    grid-auto-flow: column; */
    grid-template-columns: repeat(5, 53px);
    grid-template-rows: repeat(auto-fit, 53px);
    gap: 16px;

    .empty-box {
        display: none;

        @media (max-width:1800px) {
            display: block;
            grid-column: span 2 / -1;
            grid-row: span 2 /-1;
        }

        @media (max-width:1300px) {
            grid-column: span 3 / -1;
            grid-row: span 2 /-1;
        }

        @media (max-width:1170px) {
            grid-column: span 4 / -1;
            grid-row: span 2 /-1;
        }

        @media (max-width:1023px) {
            grid-column: span 2 / -1;
            grid-row: 1;
        }

        @media (max-width:560px) {
            grid-column: span 2 / -1;
            grid-row: 2;
        }
    }

    @media (max-width:1023px) {
        gap: 15px;
        grid-template-columns: repeat(auto-fit, 36px);
        grid-template-rows: repeat(auto-fit, 36px);
    }
}

.socials-container {
    @media (max-width:1800px) {
        max-width: 460px;
    }

    @media (max-width:1450px) {
        max-width: 100%;
    }

    .social-item {
        &:hover {
            box-shadow: 0px 0px 0 2.5px #090630, 0px 0px 0 4px #ff5935;
        }
    }

}

.payments-container {
    max-width: 480px;

    @media (max-width:1023px) {
        max-width: 100%;
    }
}

.social-item {
    position: relative;
    width: 53px;
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: #DA4727;
    transition: box-shadow .3s ease;

    .social-img {
        filter: invert(1)
    }

    @media (max-width:1023px) {
        width: 36px;
        height: 36px;
        padding: 8px;
    }
}

.social-country {
    position: absolute;
    bottom: 0;
    right: 0;

    @media (max-width:1023px) {
        width: 15px;
        height: 15px;
    }
}
</style>
