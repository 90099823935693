<template>
	<NuxtImg v-if="src" v-bind="{...$attrs, ...props}" :src="String(src)" />
	<img v-else v-bind="{...$attrs, ...props}" :src="fallback ?? defaultFallback" />
</template>

<script lang="ts" setup>
defineOptions({ inheritAttrs: false })
const props = defineProps<{
	src?: string | null | number
	width?:string
	height?: string
	fallback?: string
}>()

const defaultFallback = useAsset('/assets/images/png/default-imgs/noimage.png')
</script>