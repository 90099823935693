import type { productData } from "./products/types/productTypes";

export function usePayModal() {
	const { add, remove } = useBodyOverflowDependence()
	const currentProduct = useState<productData | null>('product-full-popup', () => null);

	const isPayModalOpen = useState<boolean>('is-pay-modal-open', () => false);

	function openPopup(product: productData) {
		currentProduct.value = product;
		isPayModalOpen.value = true;
		add('payModal')
	}

	function closePopup() {
		isPayModalOpen.value = false;
		currentProduct.value = null;
		remove('payModal')
	}

	return {
		currentProduct,
		isPayModalOpen,
		openPopup,
		closePopup
	};
}
