type Props = { title: string }

export function useModalNotice() {
	const { add, remove } = useBodyOverflowDependence()
	const noticeProps = useState<Props | null>('notice-modal-props', () => null);
	const isModalNoticeOpen = useState<boolean>('is-modal-notice-open', () => false);

	function openPopup(props:Props) {
		noticeProps.value = props
		isModalNoticeOpen.value = true;
		add('modalNotice')
	}
	
	function closePopup() {
		isModalNoticeOpen.value = false;
		noticeProps.value = null;
		remove('modalNotice')
	}

	return {
		isModalNoticeOpen,
		openPopup,
		closePopup,
		noticeProps
	};
}
