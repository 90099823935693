export function useWishDeleteModal() {
	const { add, remove } = useBodyOverflowDependence()
	const isWishDeleteModalOpen = useState<boolean>('is-wish-delete-modal-open', () => false);
	const wishProductId = useState<number | null>('is-success-modal-linkTo');

	function openDeleteFromWishPopup(product_id: number) {
		isWishDeleteModalOpen.value = true;
		wishProductId.value = product_id;
		add('deleteWishModal')
	}

	function closeDeleteFromWishPopup() {
		isWishDeleteModalOpen.value = false;
		wishProductId.value = null;
		remove('deleteWishModal')
	}

	return {
		isWishDeleteModalOpen,
		openDeleteFromWishPopup,
		closeDeleteFromWishPopup,
		wishProductId
	};
}
