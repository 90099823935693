export function useSuccessModal() {
	const { add, remove } = useBodyOverflowDependence()
	const isSuccessModalOpen = useState<boolean>('is-success-modal-open', () => false);
	const linkTo = useState<'profile' | 'products' | null>('is-success-modal-linkTo');

	function openPopup(_linkTo:'profile' | 'products') {
		isSuccessModalOpen.value = true;
		linkTo.value = _linkTo;
		add('successModal')
	}

	function closePopup() {
		isSuccessModalOpen.value = false;
		linkTo.value = null;
		remove('successModal')
	}

	return {
		isSuccessModalOpen,
		openPopup,
		closePopup,
		linkTo
	};
}
