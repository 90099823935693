import { ref } from 'vue'
import type { productData, productPagination, productRequestType } from '../products/types/productTypes'

export const useWish = () => {
    const wishProducts = ref<productPagination>()
    const wishProductIds = ref<number[]>([])
    const { $api } = useNuxtApp()

    const getWishProducts = async (params: productRequestType) => {
        const res = await $api<productPagination>('/wish/all', {
            params: {...params}
        })
        if (res) {
            wishProducts.value = res
            return res
        }
        return null
    }

    const getWishProductIds = async () => {
        const res = await $api<{productIds: number[]}>('/wish/get-products-ids')
        if (res && res.productIds) {
            wishProductIds.value = res.productIds
            return res.productIds
        }
        return null
    }

    const addToWish = async (product_id: number) => {
        const res = await $api('/wish/add', {
            body: { product_id },
            method: 'POST'
        })
        if (res) {
            return res
        }
        return null
    }

    const removeFromWish = async (id: number) => {
        const res = await $api(`/wish/delete/${id}`, {
            method: 'DELETE'
        })
        if (res) {
            return res
        }
        return null
    }

    return {
        wishProducts,
        wishProductIds,
        getWishProducts,
        getWishProductIds,
        addToWish,
        removeFromWish
    }
}