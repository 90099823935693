import type { Currency, CurrencyRate } from "./types/currencyTypes";
import { useCurrencyStore } from "~/stores/currency";
export function useCurrency() {
    const { $api } = useNuxtApp(); 
    const currencyStore = useCurrencyStore();
    const getAllCurrencies = async (): Promise<Currency[]> => {
      try {
        const res = await $api<Currency[]>(`/currency/all`, {
          method: 'GET',
        });
        currencyStore.setCurrencies([...res, {
          code: 'USD',
          symbol: '$',
          rate: 1,
          currency: 'USD'
        }]);
        return res;
      } catch (error) {
        console.error('Error fetching all currencies:', error);
        return [];
      }
    };
  
    const getCurrencyById = async (id: string): Promise<any | null> => {
      try {
        const res = await $api<Currency>(`/currency/${id}`, {
          method: 'GET',
        });
        return res;
      } catch (error) {
        console.error('Error fetching currency by ID:', error);
        return null;
      }
    };
  
    const getCurrencyCourse = async (currency: string): Promise<CurrencyRate | null> => {
      if (currency === 'USD') {
        currencyStore.setCurrentCurrencyRate(1);
        return {rate: 1}
      }
      try {

        const res = await $api<CurrencyRate>(`/currency/get-course/${currency}`, {
          method: 'GET',
        });
        return res;
      } catch (error) {
        console.error('Error fetching currency course:', error);
        return null;
      }
    };
  
    return {
      getAllCurrencies,
      getCurrencyById,
      getCurrencyCourse
    };
  }