import { useUserStore } from "#imports";
import { useUserAuth } from "~/composables/user/UseUserAuth";
import { useproductstore } from "#imports";
// тут можно загружать и подключать все что нужно инициализировать на стророе сервера при старте
export default defineNuxtPlugin(async (nuxtApp) => {
	const userStore = useUserStore()
	const { isLoggedIn } = storeToRefs(userStore)
	const { getWishIds } = useproductstore();
	const { getUserMe } = useUserAuth();
	await getUserMe()
	if (isLoggedIn.value) {
		await getWishIds();
	}
});
