export default defineNuxtPlugin({
	name: 'customFetch',

	setup(_nuxtapp) {
		const { baseUrl } = useRuntimeConfig().public;

		const sessionToken = useCookie('sessionToken', { maxAge: (60 * 60 * 24 * 30 * 12), path: '/' });
		console.log(sessionToken.value)
		if (!sessionToken.value) {
			sessionToken.value = `f-${[...Array(11)].map(() => (~~(Math.random() * 36)).toString(36)).join('')}`;
		}
		const { $i18n } = useNuxtApp()
		const { locale } = $i18n

		const cookiesHeaders = useRequestHeaders(['cookie']);

		const headers: { [key: string]: string } = {
			Accept: 'application/json',
			...cookiesHeaders,
			'X-Session-Token': sessionToken.value,
			'x-user-locale': locale.value,
		};

		const ofetchInstanse = $fetch.create({
			baseURL: baseUrl,
			// baseURL: '/api-proxi/',
			headers: {
				...headers
			},

			onRequest({ request, options, error }) {
				const authToken = useCookie('authToken');
				if (authToken.value) {
					options.headers.set('Authorization', authToken.value);
				}
			}
		});
		return {
			provide: {
				api: ofetchInstanse
			}
		};
	}
});
