// Store for catalog management
// Contains three lists - all, sorted, and allProductTypes
// all stores all CatalogItemType items
// sorted stores categorized items based on the setSorted action
// allProductTypes stores all ProductType items
import type { CatalogItemType, ProductType } from "~/composables/catalog/types/catalogTypes"
import type { productData } from "~/composables/products/types/productTypes"
export const useCatalogStore = defineStore('catalog-store', {
    state: () => ({
        all: [] as CatalogItemType[],
        sorted: [] as { name: string; items: CatalogItemType[] }[],
        allProductTypes: {} as { [id: number]: ProductType },
        allProductTypesFromEndPoing: [] as ProductType[],
        majorCategories: [] as { id: number, name: string }[],
        allCountries: [] as { id: number, name: string }[],
        isCatalogOpen: false,
        chosenCategory: 481 as string | number
    }),
    getters: {
        getAll(): CatalogItemType[] {
            return this.all
        },
        getSorted(): { name: string; items: CatalogItemType[] }[] {
            return this.sorted
        },
        getAllProductTypes(): ProductType[] {
            return Object.values(this.allProductTypes)
        }
    },
    actions: {
        setCatalogItems(items: CatalogItemType[]) {
            this.all = items;
            // Update allProductTypes state
            const allProductTypes = items.reduce((acc, item) => {
                item.product_types.forEach((productType) => {
                    //@ts-ignore
                    if (!acc[productType.id]) {
                        //@ts-ignore
                        acc[productType.id] = productType;
                    }
                });
                return acc;
            }, {} as { [id: number]: ProductType });
            this.allProductTypes = allProductTypes;
            this.setSorted('services')
        },

        setAllCountries(countries: { name: string, id: number }[]) {
            this.allCountries = countries
        },

        setSorted(category: string | number) {
            if (Number(category)) {
                const items = this.all.filter(item => item.category_id === Number(category));
                // Sort and categorize items by first letter
                const sortedItems = items.sort((a, b) => {
                    const locale = useNuxtApp().$i18n.locale.value;
                    return a.name.localeCompare(b.name);
                });
                const categorizedItems = sortedItems.reduce((acc, item) => {
                    const locale = useNuxtApp().$i18n.locale.value;
                    const firstLetter = item.name[0].toUpperCase();
                    if (!acc[firstLetter]) {
                        acc[firstLetter] = { name: firstLetter, items: [] };
                    }
                    acc[firstLetter].items.push(item);
                    return acc;
                }, {} as { [key: string]: { name: string; items: CatalogItemType[] } });
                this.sorted = Object.values(categorizedItems);
            } else {
                const productType = Object.values(this.allProductTypes).find((type: ProductType) => type.slug === category);
                if (productType) {
                    const items = this.all.filter(item => item.product_types.some((type: ProductType) => type.id === productType.id));
                    // Sort and categorize items by first letter
                    const sortedItems = items.sort((a, b) => {
                        const locale = useNuxtApp().$i18n.locale.value;
                        return a.name.localeCompare(b.name);
                    });
                    const categorizedItems = sortedItems.reduce((acc, item) => {
                        const locale = useNuxtApp().$i18n.locale.value;
                        const firstLetter = item.name[0].toUpperCase();
                        if (!acc[firstLetter]) {
                            acc[firstLetter] = { name: firstLetter, items: [] };
                        }
                        acc[firstLetter].items.push(item);
                        return acc;
                    }, {} as { [key: string]: { name: string; items: CatalogItemType[] } });
                    this.sorted = Object.values(categorizedItems);
                }
            }
        },

        setAllProductTypesFromEndpoint(product_types: ProductType[]) {
            this.allProductTypesFromEndPoing = product_types
        },

        setMajorCategories(categories: { id: number, name: string }[]) {
            this.majorCategories = categories
        },

        setIsCatalogOpen(value: boolean) {
            this.isCatalogOpen = value
            const { add, remove } = useBodyOverflowDependence()
            if (this.isCatalogOpen) {
                add('catalogHeader')
            } else {
                remove('catalogHeader')
            }
        },

        setChosenCategory(category: string | number) {
            this.chosenCategory = category
        }
    }
})