
import type { SearchResponse, SearchRequestType } from './types/searchTypes';

export function useSearch() {
	const { $api } = useNuxtApp();
	const { setSearch } = useSearchStore()

	const performSearch = async (options: SearchRequestType) => {
		const res = await $api<SearchResponse>('/search', {
			method: 'GET',
			params: {
				...options
			}
		});

		if (res) {
			setSearch(res)
			return res;
		}
		return null;
	}

	return {
		performSearch
	}
}
