export function useBodyOverflowDependence() {
	const bodyOverflowDependence = useState<Set<string>>('bodyOverflowDependence', () => { return new Set() });

	const add = (key: string) => {
		bodyOverflowDependence.value.add(key)
	}

	const remove = (key: string) => {
		bodyOverflowDependence.value.delete(key)
	}

	return {
		bodyOverflowDependence,
		add,
		remove,
	};
}
