<template>
	<div class="profile-avatar-dropdown">
		
<FormKitLazyProvider config-file="true" :default-config="false">
<NuxtImgC :src="user?.user?.avatar" class="profile-avatar-img" :fallback="useAsset('/assets/images/svg/profile/avatar.svg')" alt="" />
		<div class="profile-avatar-plus">
			<img src="/assets/images/svg/plus-white.svg" alt="">
		</div>
		<div class="profile-avatar-overlay">
			<FormKit @input="uploadAvatar" type="file" accept=".png,.jpg,.jpeg">
				<template #prefix>
					<img src="/assets/images/svg/plus-circle.svg" alt="">
					{{ $t('profile.upload_avatar') }}
				</template>
			</FormKit>
			<div class="profile-avatar-overlay-divider"></div>
			<button :disabled="isLoadingDelete" @click="deleteAvatar" class="profile-avatar-overlay-btn">
				<img src="/assets/images/svg/x-profile.svg" alt="">
				{{ $t('profile.delete_avatar') }}
			</button>
		</div>
</FormKitLazyProvider>

	</div>
</template>

<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import { useGstockImages } from '~/composables/images/UseGstockImages';
import { useUserAuth } from '~/composables/user/UseUserAuth';
import type { FormKitFile } from '@formkit/inputs'
import { formatErrorMessages } from '~/assets/functions';

const { $toast } = useNuxtApp()
const { t } = useI18n()

const { user } = storeToRefs(useUserStore())

const { userUpdate, getUserMe } = useUserAuth()
const { createImage } = useGstockImages()

const uploadAvatar = async (file: FormKitFile[] | undefined) => {
	if (!file) return
	const body: FormData = new FormData()
	file.forEach((fileItem) => {
		if (fileItem.file) body.append('file', fileItem.file)
	})
	if (!body.has('file')) return

	await createImage(body)
		.catch(err => {
			$toast.error(err ? formatErrorMessages(err) : t('interface.img_upload_error'))
		})
		.then(async (res) => {
			if (!res) return
			return await userUpdate({
				avatar: res.image.id,
			})
		})
		.catch(err => {
			$toast.error(err ? formatErrorMessages(err) : t('profile.upload_avatar_error'))
		})
		.then(async (res) => {
			if (!res) return
			$toast.success(t('profile.upload_avatar_success'))
			await getUserMe()
		})
		.catch(err => console.log(err))
}

const isLoadingDelete = ref(false)

const deleteAvatar = async () => {
	isLoadingDelete.value = true
	await userUpdate({
		avatar: null,
	})
		.catch((err) => {
			isLoadingDelete.value = false
			$toast.error(err ? formatErrorMessages(err) : t('profile.delete_avatar_error'))
		})
		.then(async () => {
			$toast.success(t('profile.delete_avatar_success'))
			await getUserMe()
		})
		.catch(() => isLoadingDelete.value = false)
		.then(() => isLoadingDelete.value = false)
}
</script>

<style lang="scss" scoped>
.profile-avatar-dropdown {
	position: relative;
	cursor: pointer;
	width: fit-content;

	&:hover {
		.profile-avatar-overlay {
			display: block;
		}

		.profile-avatar-plus {
			display: flex;
		}
	}
}

.profile-avatar-plus {
	display: none;
	position: absolute;
	inset: 8px;
	border-radius: 100px;
	border: 1.5px solid #fff;
	background: #090630;
	align-items: center;
	justify-content: center;

	img {
		width: 32px;
		height: 32px;
	}
}

.profile-avatar-img {
	position: relative;
	width: 80px;
	height: 80px;
	object-fit: cover;
	object-position: center;
	border-radius: 100%;
}

.profile-avatar-overlay {
	display: none;
	position: absolute;
	z-index: 2;
	top: 100%;
	left: 0;
	transform: translateY(-5px);
	min-width: 240px;
	border: 1px solid #dbdbdb;
	border-radius: 13px;
	box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.35);
	background: #fff;
	padding: 16px 20px;
	cursor: auto;

	:deep(.formkit-outer) {
		.formkit-no-files {
			display: none;
		}

		.formkit-inner {
			position: relative;
			background: transparent;
			border: none;
			border-radius: 0;

			display: flex;
			align-items: center;
			gap: 7px;

			font-weight: 400;
			font-size: 20px;
			line-height: 80%;
			letter-spacing: -0.03em;
			color: #1D254A;
			cursor: pointer;

			img {
				opacity: 50%;
			}

			&:hover {
				color: #DA4727;

				img {
					filter: invert(41%) sepia(37%) saturate(4564%) hue-rotate(345deg) brightness(87%) contrast(95%)
				}
			}

			input {
				&::-webkit-file-upload-button {
					cursor: pointer;
				}
			}
		}

		.formkit-input {
			cursor: pointer;
		}

		.formkit-file-list {
			display: none;
		}
	}
}

.profile-avatar-overlay-btn {
	display: flex;
	align-items: center;
	gap: 7px;

	font-weight: 400;
	font-size: 20px;
	line-height: 80%;
	letter-spacing: -0.03em;
	color: #1D254A;
	cursor: pointer;

	img {
		opacity: 50%;
	}

	&:hover {
		color: #DA4727;

		img {
			filter: invert(41%) sepia(37%) saturate(4564%) hue-rotate(345deg) brightness(87%) contrast(95%)
		}
	}
}

.profile-avatar-overlay-divider {
	height: 1px;
	width: 100%;
	background: #d9d9dc;
	margin: 12px 0;
}
</style>