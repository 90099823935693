import payload_plugin_qLmFnukI99 from "/var/www/gstock.org/gstock-front-20250313060501/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/var/www/gstock.org/gstock-front-20250313060501/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/gstock.org/gstock-front-20250313060501/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/gstock.org/gstock-front-20250313060501/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_jtc2qNDx4l from "/var/www/gstock.org/gstock-front-20250313060501/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/var/www/gstock.org/gstock-front-20250313060501/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/www/gstock.org/gstock-front-20250313060501/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/gstock.org/gstock-front-20250313060501/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/var/www/gstock.org/gstock-front-20250313060501/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/var/www/gstock.org/gstock-front-20250313060501/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/var/www/gstock.org/gstock-front-20250313060501/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/gstock.org/gstock-front-20250313060501/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_4F1kZh3YAB from "/var/www/gstock.org/gstock-front-20250313060501/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import plugin_adVF2uRka6 from "/var/www/gstock.org/gstock-front-20250313060501/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import plugin_bKSoGl1L7p from "/var/www/gstock.org/gstock-front-20250313060501/node_modules/yandex-metrika-module-nuxt3/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/var/www/gstock.org/gstock-front-20250313060501/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/var/www/gstock.org/gstock-front-20250313060501/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_42RIGIx6e8 from "/var/www/gstock.org/gstock-front-20250313060501/node_modules/@nuxt/image/dist/runtime/plugin.js";
import _0_error_handler_client_ZZbDvGqH3x from "/var/www/gstock.org/gstock-front-20250313060501/plugins/0.error-handler.client.ts";
import bodyOverflowDependencePlugin_2kYORMrbwd from "/var/www/gstock.org/gstock-front-20250313060501/plugins/bodyOverflowDependencePlugin.ts";
import changeCurrency_7r5PTkZYIH from "/var/www/gstock.org/gstock-front-20250313060501/plugins/changeCurrency.ts";
import customoOfetch_zBJTuv7VXR from "/var/www/gstock.org/gstock-front-20250313060501/plugins/customoOfetch.ts";
import initPlugin_VEF11A2vjr from "/var/www/gstock.org/gstock-front-20250313060501/plugins/initPlugin.ts";
import initPluginClient_client_KE9YEyCHtR from "/var/www/gstock.org/gstock-front-20250313060501/plugins/initPluginClient.client.ts";
import pageStatus_client_2QG6xNWPuO from "/var/www/gstock.org/gstock-front-20250313060501/plugins/pageStatus.client.ts";
import priceFormat_Cb2yhyBrV2 from "/var/www/gstock.org/gstock-front-20250313060501/plugins/priceFormat.ts";
import recaptcha_W4US4xmBV6 from "/var/www/gstock.org/gstock-front-20250313060501/plugins/recaptcha.js";
import setCanonical_Ok2TJU0Ur6 from "/var/www/gstock.org/gstock-front-20250313060501/plugins/setCanonical.ts";
import toast_ysMjUcU7eJ from "/var/www/gstock.org/gstock-front-20250313060501/plugins/toast.ts";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_jtc2qNDx4l,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_4F1kZh3YAB,
  plugin_adVF2uRka6,
  plugin_bKSoGl1L7p,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_42RIGIx6e8,
  _0_error_handler_client_ZZbDvGqH3x,
  bodyOverflowDependencePlugin_2kYORMrbwd,
  changeCurrency_7r5PTkZYIH,
  customoOfetch_zBJTuv7VXR,
  initPlugin_VEF11A2vjr,
  initPluginClient_client_KE9YEyCHtR,
  pageStatus_client_2QG6xNWPuO,
  priceFormat_Cb2yhyBrV2,
  recaptcha_W4US4xmBV6,
  setCanonical_Ok2TJU0Ur6,
  toast_ysMjUcU7eJ
]