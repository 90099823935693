import type { productData } from "./products/types/productTypes";

export function useSteamPayModal() {
	const { add, remove } = useBodyOverflowDependence()
	const currentProduct = useState<productData | null>('current-product-steam-pay-modal', () => null);
	const isSteamPayModalOpen = useState<boolean>('is-steam-pay-modal-open', () => false);

	function openPopup(product: productData) {
		currentProduct.value = product;
		isSteamPayModalOpen.value = true;
		add('paySteamModal')
	}

	function closePopup() {
		isSteamPayModalOpen.value = false;
		currentProduct.value = null;
		remove('paySteamModal')
	}

	return {
		isSteamPayModalOpen,
		openPopup,
		closePopup,
		currentProduct
	};
}
