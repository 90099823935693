<template>
	<div class="header__lang" @click.stop="toggleLangList()" id="header-lang-changer13trhhqoewd">
		<img :src="`/icons/flag-${locale}.svg`" class="icon-flag" />
		<span>{{ $t(`locale.${locale}`) }}</span>
		<img :src="`/icons/arrow.svg`" class="icon-arrow" :class="{ 'rotate-180': showLangList }" />
		<ul class="lang__list" :class="showLangList ? 'active' : ''">
			<template v-for="loc in locales.filter(loc => loc.code !== locale)">
				<div @click="() => changeLocale(loc.code)">
					<li class="lang-item">
						<img :src="`/icons/flag-${loc.code}.svg`" class="icon-flag" />
						<span>{{ $t(`locale.${loc.code}`) }}</span>
					</li>
				</div>
			</template>
		</ul>
	</div>
</template>

<script setup lang="ts">
import {useRoute} from "#app";
const { locale, locales } = useI18n();
const emits = defineEmits(['changeOpen']);
const showLangList = ref(false);
const localePath = useLocalePath();
const clickOutsideHandler = (event: MouseEvent) => {
	//@ts-ignore
	if (event.target?.id !== 'header-lang-changer13trhhqoewd') {
		showLangList.value = false
	}
}

watch(showLangList, (newVal) => {
	if (newVal === true) {
		document.addEventListener('click', clickOutsideHandler)
	} else {
		document.removeEventListener('click', clickOutsideHandler)
	}
})

async function changeLocale(localeCode: 'ru' | 'en') {
  const localeI18nRedirect = useCookie('i18n_redirect');
  localeI18nRedirect.value = localeCode;
  refreshCookie('i18n_redirect');

  const $route = useRoute();
  window.history.replaceState(null, '', localePath($route.fullPath, localeCode));
  window.location.reload();
}

function toggleLangList() {
	showLangList.value = !showLangList.value;
}
</script>

<style scoped lang="scss">
.icon-flag {
	width: 16px;
	height: 16px;
}

.lang-item {
	display: flex;
	flex-direction: row;
	gap: 4px;
}

.icon-arrow {
	transition: all 0.3s ease-in-out;

}

.rotate-180 {
	transform: rotate(180deg);
}

.header__lang {
	display: flex;
	align-items: center;
	padding: 0px 4px;
	gap: 4px;
	color: #090630;
	cursor: pointer;
	position: relative;
  line-height: 1;

	.lang__list {
		display: none;
		position: absolute;
		min-width: max-content;
		width: 100%;
		top: 100%;
		z-index: 100;
		left: 0;
		background: var(--white);
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
		padding: 8px 0;
		margin-top: 8px;
		border-bottom-right-radius: 8px;
		border-bottom-left-radius: 8px;

		&.active {
			display: block;
		}

		li {
			padding: 4px 4px;
			transition: background .3s ease;

			&:hover {
				background: #f5f5f5;
			}
		}
	}
}
</style>
