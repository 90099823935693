import validate from "/var/www/gstock.org/gstock-front-20250313060501/node_modules/nuxt/dist/pages/runtime/validate.js";
import page_45transition_45locale_45global from "/var/www/gstock.org/gstock-front-20250313060501/middleware/pageTransitionLocale.global.ts";
import manifest_45route_45rule from "/var/www/gstock.org/gstock-front-20250313060501/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  page_45transition_45locale_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/var/www/gstock.org/gstock-front-20250313060501/middleware/auth.ts")
}