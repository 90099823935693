export default defineNuxtRouteMiddleware((to, from) => {
	const localePath = useLocalePath()
	if (to.path !== from.path && localePath(to.path, 'en') === localePath(from.path, 'en')) {
		from.meta.pageTransition = { name: '' }
		to.meta.pageTransition = { name: '' }
		return
	}
	if (typeof from.meta.pageTransition !== 'boolean' && from.meta.pageTransition?.name === '') {
		from.meta.pageTransition = undefined
	}
});
