export function useNomoneyModal() {
	const { add, remove } = useBodyOverflowDependence()
	const isNomoneyModalOpen = useState<boolean>('is-nomoney-modal-open', () => false);

	function openPopup() {
		isNomoneyModalOpen.value = true;
		add('nomoneyModal')
	}

	function closePopup() {
		isNomoneyModalOpen.value = false;
		remove('nomoneyModal')
	}

	return {
		isNomoneyModalOpen,
		openPopup,
		closePopup
	};
}
