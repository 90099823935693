import type { CatalogItemType, categories, ProductType, ServiceType } from './types/catalogTypes';
import { useCatalogStore } from '~/stores/catalog';

export function useCatalog() {
  const { $api } = useNuxtApp(); 
  const { setCatalogItems, setAllProductTypesFromEndpoint, setMajorCategories, setAllCountries } = useCatalogStore(); 


  const getServiceType = async (id: string): Promise<{category: ServiceType} | null> => {
    try {
      const res = await $api<{category: ServiceType} >(`/category/${id}`, {
        method: 'GET',
      });
      return res;
    } catch (error) {
      console.error('Error fetching service type:', error);
      return null;
    }
  };

  const getCategories = async (): Promise<void> => {
    try {
      const res = await $api<categories>('/category/get-categories', {
        method: 'GET',
      });
      if (res) {
        setCatalogItems(res.categories); 
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const getAllProductTypes = async (): Promise<void> => {
    const res = await $api<{type_products: ProductType[]}>('/type-product/all')
    if (res) {
      setAllProductTypesFromEndpoint(res.type_products)
    }
  }

  const getAllCountries = async (): Promise<void> => {
    const res = await $api<{countries: {name: string, id: number}[]}>('/country/countries')
    if (res) {
      setAllCountries(res.countries)
    }
  }

  const getMajorCategories = async (): Promise<{id: number, name: string}[]> => {
    try {
      const res = await $api<{categories: {id: number, name: string}[]}>('/category/get-major-categories', {
        method: 'GET',
      });
      setMajorCategories(res.categories)
      return res.categories;
    } catch (error) {
      console.error('Error fetching major categories:', error);
      return [];
    }
  };

  return {
    getCategories,
    getAllProductTypes,
    getServiceType,
    getMajorCategories,
    getAllCountries
  };
}