import type { SearchResponse } from '@/composables/search/types/searchTypes';

export const useSearchStore = defineStore('search-store', {
	state: () => ({
		search: { products: [], shops: [] } as SearchResponse,
	}),
	actions: {
		setSearch(value: SearchResponse) {
			this.search = value
		},
	},
	getters: {
		searchCounts: (state) => {
			const products = state.search.products.length
			const stores = state.search.shops.length
			const all = products + stores
			return { stores, products, all } as any
		}
	}
})