<template>
  <div class="page-box">
    <Header />
    <HeaderSticky />
    <main class="page-box-main">
      <NuxtPage />
    </main>
    <Footer />
    <!-- <ModalUved /> -->
    <ModalAppWrapper />
  </div>
  <div v-for="item in data" v-html="item.code"></div>
</template>
<script setup lang="ts">
import Footer from './components/_footer/Footer.vue';
import HeaderSticky from './components/_header/HeaderSticky.vue';
import { useBackPlugins } from './composables/metrics/useBackPlugins';

const { getBackPlugins } = useBackPlugins();
const { data } = await useAsyncData('plugins', () => getBackPlugins());
</script>
<style lang="scss" scoped>
.page-box {
  min-height: 100vh;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
}

.page-box-main {
  flex-grow: 1;
}
</style>
