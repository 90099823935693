type ActionType = 'impressions' | 'click' | 'detail' | 'add' | 'remove' | 'purchase' | 'promoView' | 'promoClick'
export function useYm() {
	const { yandexMetrika } = useRuntimeConfig().public
	const reachGoal = (target: string) => {
		if (yandexMetrika.isDev) return
		/* @ts-ignore */
		if (window && window.ym) {
			/* @ts-ignore */
			window.ym?.(yandexMetrika.id, 'reachGoal', target)
		}
	}

	const ecommercePush = (actionType: ActionType, actionTypeData: object | undefined) => {		
		/* @ts-ignore */
		if (window && window?.dataLayer) {
			/* @ts-ignore */
			window.dataLayer.push({
				"ecommerce": {
					currencyCode: 'USD',
					[actionType]: actionTypeData
				}
			})
		}
	}

	return {
		reachGoal,
		ecommercePush
	};
}
