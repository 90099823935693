import type { productData } from '@/composables/products/types/productTypes';
import { useProducts } from '~/composables/products/useProducts';
import { useWish } from '~/composables/wish/useWish';

export const useproductstore = defineStore('products-store', {
	state: () => ({
		products_service_home: [] as productData[],
		products_games_home: [] as productData[],
		products_popular_home: [] as productData[],
		wish_ids: [] as number[],
	}),
	actions: {
		async getGamesHome() {
			const { getProducts } = useProducts()
			const res = await getProducts({ limit: 12, page: 1, category_id: 481 })
			this.products_games_home = res?.data ?? []
		},
		async getServicesHome() {
			const { getProducts } = useProducts()
			const res = await getProducts({ limit: 12, page: 1, category_id: 482 })
			this.products_service_home = res?.data ?? []
		},
		async getPopularProductsHome() {
			const { getProducts } = useProducts()
			const res = await getProducts({ limit: 10, page: 1, isPopular: 1, isHome:1 })
			this.products_popular_home = res?.data ?? []
		},
		async getWishIds() {
			const { getWishProductIds } = useWish()
			const res = await getWishProductIds()
			this.wish_ids = res ?? []
		},
		
	}
})