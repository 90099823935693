import { useCatalog } from "~/composables/catalog/useCatalog";
import { useUserAuth } from "~/composables/user/UseUserAuth";
import { useCurrency } from "~/composables/currency/useCurrency";

export default defineNuxtPlugin({
	name: 'initPlugin',
	async setup(nuxtApp) {
		// тут можно загружать и подключать все что нужно инициализировать при старте
		const { getAllCurrencies } = useCurrency();
		const { getMajorCategories, getCategories } = useCatalog();
		await callOnce(async () => {
			await Promise.all([
				getAllCurrencies(),
				getMajorCategories(),
				getCategories()
			])
		})
	},
})