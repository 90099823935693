export function useSuccessfullyRegistredStoreModal() {
	const { add, remove } = useBodyOverflowDependence()
	const isSuccesfullyRegistredStoreModalOpen = useState<boolean>('is-sucessfully-registred-store-modal-open', () => false);

	function openPopup() {
		isSuccesfullyRegistredStoreModalOpen.value = true;
		add('storeSuccesfullyRegsistredModal')
	}

	function closePopup() {
		isSuccesfullyRegistredStoreModalOpen.value = false;
		remove('storeSuccesfullyRegsistredModal')
	}

	return {
		isSuccesfullyRegistredStoreModalOpen,
		openPopup,
		closePopup
	};
}
