export const useModal = (key:string = 'modal') => {
	const { add, remove } = useBodyOverflowDependence()
	const isModalShow = ref(false);

	const openModal = () => {
		isModalShow.value = true;
		add(key)
	};

	const closeModal = () => {
		isModalShow.value = false;
		remove(key)
	};

	return { isModalShow, closeModal, openModal };
};
