import { useState } from '#app'

export const useBackLink = () => {
  const backLink = useState<string>('profile-back-link', () => '')
  
  const setBackLink = (path: string) => {
    backLink.value = path
    // Clear backlink after 10 minutes
    setTimeout(() => {
      backLink.value = ''
    }, 10 * 60 * 1000)
  }

  return {
    backLink,
    setBackLink
  }
} 