import type { Currency } from '~/composables/currency/types/currencyTypes'

export const useCurrencyStore = defineStore('currency-store', {
	state: () => ({
		currencies: [] as Currency[],
		CurrentCurrency: {
			code: 'USD',
			symbol: '$',
			rate: 1,
			currency: 'USD'
		} as Currency | null,
		CurrentCurrencyRate: 1 as number
	}),

	actions: {
		setCurrencies(currencies: Currency[]) {
			this.currencies = currencies
		},
		setCurrentCurrency(currency: Currency) {
			this.CurrentCurrency = currency
		},
		setCurrentCurrencyRate(rate: number) {
			this.CurrentCurrencyRate = rate
		}
	}
})
