<template>
	<div class="search-popup-content scroll-customize">
		<AtomsTabs class="search-page-tabs">
			<template #title="{ title, name }: { title: string, name: 'stores' | 'products' | 'all' }">
				{{ title }} <span v-if="name !== 'all'">{{ searchCounts?.[name] || '-' }}</span>
			</template>
			<AtomsTabsTab name="all" :title="$t('search.everywhere')">
				<span v-if="isLoading" class="loader"></span>
				<template v-else-if="searchCounts.all">
					<div class="seacrh-group" v-if="searchCounts.products">
						<h2 class="seacrh-group-title">{{ $t('search.products') }}</h2>
						<div class="seacrh-group-content">
							<SearchProduct v-for="product in products" :product />
							<NuxtLinkLocale :to="`/search?search=${query}&open-tab=products`" v-if="searchCounts.products > 6" class="btn btn-secondary btn-outline col-span-full">{{ $t('search.show_all') }}</NuxtLinkLocale>
						</div>
					</div>
					<div class="seacrh-group" v-if="searchCounts.stores">
						<h2 class="seacrh-group-title">{{ $t('search.stores') }}</h2>
						<div class="seacrh-group-content">
							<SearchStore v-for="shop in shops" :shop />
							<NuxtLinkLocale :to="`/search?search=${query}&open-tab=stores`" v-if="searchCounts.stores > 5" class="btn btn-secondary btn-outline col-span-full">{{ $t('search.show_all') }}</NuxtLinkLocale>
						</div>
					</div>
				</template>
				<SearchEmpty v-else />
			</AtomsTabsTab>
			<AtomsTabsTab name="products" :title="$t('search.products')">
				<span v-if="isLoading" class="loader"></span>
				<div class="seacrh-group" v-else-if="searchCounts.products">
					<h2 class="seacrh-group-title">{{ $t('search.products') }}</h2>
					<div class="seacrh-group-content">
						<SearchProduct v-for="product in products" :product />
						<NuxtLinkLocale :to="`/search?search=${query}&open-tab=products`" v-if="searchCounts.products > 6" class="btn btn-secondary btn-outline col-span-full">{{ $t('search.show_all') }}</NuxtLinkLocale>
					</div>
				</div>
				<SearchEmpty v-else />
			</AtomsTabsTab>
			<AtomsTabsTab name="stores" :title="$t('search.stores')">
				<span v-if="isLoading" class="loader"></span>
				<div class="seacrh-group" v-else-if="searchCounts.stores">
					<h2 class="seacrh-group-title">{{ $t('search.stores') }}</h2>
					<div class="seacrh-group-content">
						<SearchStore v-for="shop in shops" :shop />
						<NuxtLinkLocale :to="`/search?search=${query}&open-tab=stores`" v-if="searchCounts.stores > 5" class="btn btn-secondary btn-outline col-span-full">{{ $t('search.show_all') }}</NuxtLinkLocale>
					</div>
				</div>
				<SearchEmpty v-else />
			</AtomsTabsTab>
		</AtomsTabs>
	</div>
</template>

<script setup lang="ts">
import { useSearch } from '~/composables/search/useSearch';
const emits = defineEmits<{ close: [] }>()
const { search, searchCounts } = storeToRefs(useSearchStore())
const { performSearch } = useSearch()
const isLoading = ref(false)
const props = defineProps<{ query?: string }>()

watch(() => props.query, async (newValue, oldValue) => {
	if (!newValue) return
	isLoading.value = true
	await performSearch({ search: newValue })
	isLoading.value = false
}, { immediate: true })

const products = computed(() => {
	return search.value.products.slice(0, 6)
})

const shops = computed(() => {
	return search.value.shops.slice(0, 5)
})

useEventListener('keyup', (e) => {
	if (e.key === 'Escape') emits('close')
})

const headerBottomPosition = useState('header-bottom-position');
</script>

<style lang="scss" scoped>
.search-popup-overlay {
	position: fixed;
	inset: 0;
	background: #090630;
	opacity: 0.4;
	z-index: 9;
}

.search-popup-content {
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 14px;
	box-shadow: 0 4px 6px 0 rgba(77, 24, 13, 0.1);
	background: #fff;
	padding: 28px;
	position: absolute;
	top: calc(100% + 14px);
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	max-height: calc(100vh - v-bind(headerBottomPosition) * 1px - 10px);
	max-height: calc(100dvh - v-bind(headerBottomPosition) * 1px - 10px);
	overflow-y: auto;

	:deep(.tabs-header) {
		border-bottom: 1px solid rgba(189, 189, 189, 0.5);
		display: flex;
		gap: 40px;
	}

	:deep(.tabs-header-item) {
		padding-bottom: 17px;
		border-bottom: 5px solid transparent;
		font-size: 22px;
		line-height: 80%;
		letter-spacing: -0.03em;
		color: #858398;
		cursor: pointer;

		span {
			color: #da4727;
		}

		&.active {
			border-color: #da4727;
			color: #090630;
			font-weight: 700;
		}
	}

	.tab {
		display: flex;
		flex-direction: column;
		gap: 24px;
		margin-top: 24px;
	}
}

.seacrh-group {
	display: flex;
	flex-direction: column;
	gap: 12px;

	.btn {
		text-align: center;
		margin-top: 4px;
		padding: 9px 28px 7px 28px;
		width: fit-content;
	}
}

.seacrh-group-title {
	font-weight: 700;
	font-size: 17px;
	line-height: 80%;
	letter-spacing: -0.03em;
	color: #090630;
}

.seacrh-group-content {
	display: grid;
	grid-template-columns: 1fr;
	gap: 8px;
}

.loader {
	display: block;
	width: 48px;
	height: 48px;
	border: 5px solid #FFF;
	border-bottom-color: #da4727;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
	margin: auto;
}

.search-empty-query{
	margin-top: 0;
}

@media (max-width:1023px) {
	.search-popup-content {
		padding: 28px 8px;
		position: absolute;
		top: calc(100% + 10px);

		:deep(.tabs-header) {
			gap: 16px;
		}

		:deep(.tabs-header-item) {
			padding-bottom: 12px;
			font-size: 19px;
		}
	}

	.seacrh-group-content {
		grid-template-columns: repeat(auto-fill, minmax(154px, 1fr));
	}

}
</style>