
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91product_45type_935Vn6SFyrE6Meta } from "/var/www/gstock.org/gstock-front-20250313060501/pages/[category-type]/[productName]/[product-type].vue?macro=true";
import { default as indexnJOB4WwTu8Meta } from "/var/www/gstock.org/gstock-front-20250313060501/pages/[category-type]/[productName]/index.vue?macro=true";
import { default as indexJrfKi7tfIXMeta } from "/var/www/gstock.org/gstock-front-20250313060501/pages/about/index.vue?macro=true";
import { default as indexIWeMhYuUSkMeta } from "/var/www/gstock.org/gstock-front-20250313060501/pages/auth/index.vue?macro=true";
import { default as _91_46_46_46slug_93WupYPw65BtMeta } from "/var/www/gstock.org/gstock-front-20250313060501/pages/blog/[...slug].vue?macro=true";
import { default as indexQeEogiIWwtMeta } from "/var/www/gstock.org/gstock-front-20250313060501/pages/blog/index.vue?macro=true";
import { default as indexxDkEm3XywDMeta } from "/var/www/gstock.org/gstock-front-20250313060501/pages/confirm/index.vue?macro=true";
import { default as indexOGRx3p2cE3Meta } from "/var/www/gstock.org/gstock-front-20250313060501/pages/faq/index.vue?macro=true";
import { default as indexiugSZeNYqvMeta } from "/var/www/gstock.org/gstock-front-20250313060501/pages/feedback/index.vue?macro=true";
import { default as indexBfk9tWHvmPMeta } from "/var/www/gstock.org/gstock-front-20250313060501/pages/for_customer/index.vue?macro=true";
import { default as indexjpZ8GnjpJiMeta } from "/var/www/gstock.org/gstock-front-20250313060501/pages/for_seller/index.vue?macro=true";
import { default as indexGxkkh1V71IMeta } from "/var/www/gstock.org/gstock-front-20250313060501/pages/games/index.vue?macro=true";
import { default as indexO7ehRuauv1Meta } from "/var/www/gstock.org/gstock-front-20250313060501/pages/in-development/index.vue?macro=true";
import { default as indexBx3VHhPjorMeta } from "/var/www/gstock.org/gstock-front-20250313060501/pages/index.vue?macro=true";
import { default as indexOCkhQPLoCDMeta } from "/var/www/gstock.org/gstock-front-20250313060501/pages/payments/index.vue?macro=true";
import { default as _91name_93_45_45_91id_937cu2ns3rPNMeta } from "/var/www/gstock.org/gstock-front-20250313060501/pages/products/[name]--[id].vue?macro=true";
import { default as indexkbUiJTjLv2Meta } from "/var/www/gstock.org/gstock-front-20250313060501/pages/products/index.vue?macro=true";
import { default as indexgdeUfWOH1eMeta } from "/var/www/gstock.org/gstock-front-20250313060501/pages/profile/chat/index.vue?macro=true";
import { default as indexYyMRaeKzedMeta } from "/var/www/gstock.org/gstock-front-20250313060501/pages/profile/favorites/index.vue?macro=true";
import { default as indexlMVFEClhxSMeta } from "/var/www/gstock.org/gstock-front-20250313060501/pages/profile/history-balance/index.vue?macro=true";
import { default as index22SGTWXFR4Meta } from "/var/www/gstock.org/gstock-front-20250313060501/pages/profile/index.vue?macro=true";
import { default as indexeUkhnR6RQQMeta } from "/var/www/gstock.org/gstock-front-20250313060501/pages/profile/reviews/index.vue?macro=true";
import { default as indexPkkF96leP0Meta } from "/var/www/gstock.org/gstock-front-20250313060501/pages/profile/settings/index.vue?macro=true";
import { default as indexbyZN29FN5QMeta } from "/var/www/gstock.org/gstock-front-20250313060501/pages/registration/index.vue?macro=true";
import { default as indexpwslS7rXEBMeta } from "/var/www/gstock.org/gstock-front-20250313060501/pages/request-password/index.vue?macro=true";
import { default as index62vfJCNLKSMeta } from "/var/www/gstock.org/gstock-front-20250313060501/pages/rules/index.vue?macro=true";
import { default as indexhfN1fTnUAUMeta } from "/var/www/gstock.org/gstock-front-20250313060501/pages/search/index.vue?macro=true";
import { default as indexJ2z3xST43SMeta } from "/var/www/gstock.org/gstock-front-20250313060501/pages/services/index.vue?macro=true";
import { default as indexiqHfbx3Y3yMeta } from "/var/www/gstock.org/gstock-front-20250313060501/pages/steam-topup/index.vue?macro=true";
import { default as indexIi0o5Ez0asMeta } from "/var/www/gstock.org/gstock-front-20250313060501/pages/store-create/index.vue?macro=true";
import { default as _91name_93_45_45_91id_93p3I0HxMFKYMeta } from "/var/www/gstock.org/gstock-front-20250313060501/pages/stores/[name]--[id].vue?macro=true";
import { default as indexmT84EGgd9MMeta } from "/var/www/gstock.org/gstock-front-20250313060501/pages/stores/index.vue?macro=true";
export default [
  {
    name: "categorytype-productName-producttype___en",
    path: "/:categorytype()/:productName()/:producttype()",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/[category-type]/[productName]/[product-type].vue")
  },
  {
    name: "categorytype-productName-producttype___ru",
    path: "/ru/:categorytype()/:productName()/:producttype()",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/[category-type]/[productName]/[product-type].vue")
  },
  {
    name: "categorytype-productName___en",
    path: "/:categorytype()/:productName()",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/[category-type]/[productName]/index.vue")
  },
  {
    name: "categorytype-productName___ru",
    path: "/ru/:categorytype()/:productName()",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/[category-type]/[productName]/index.vue")
  },
  {
    name: "about___en",
    path: "/about",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/about/index.vue")
  },
  {
    name: "about___ru",
    path: "/ru/about",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/about/index.vue")
  },
  {
    name: "auth___en",
    path: "/auth",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/auth/index.vue")
  },
  {
    name: "auth___ru",
    path: "/ru/auth",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/auth/index.vue")
  },
  {
    name: "blog-slug___en",
    path: "/blog/:slug(.*)*",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/blog/[...slug].vue")
  },
  {
    name: "blog-slug___ru",
    path: "/ru/blog/:slug(.*)*",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/blog/[...slug].vue")
  },
  {
    name: "blog___en",
    path: "/blog",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/blog/index.vue")
  },
  {
    name: "blog___ru",
    path: "/ru/blog",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/blog/index.vue")
  },
  {
    name: "confirm___en",
    path: "/confirm",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/confirm/index.vue")
  },
  {
    name: "confirm___ru",
    path: "/ru/confirm",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/confirm/index.vue")
  },
  {
    name: "faq___en",
    path: "/faq",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/faq/index.vue")
  },
  {
    name: "faq___ru",
    path: "/ru/faq",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/faq/index.vue")
  },
  {
    name: "feedback___en",
    path: "/feedback",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/feedback/index.vue")
  },
  {
    name: "feedback___ru",
    path: "/ru/feedback",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/feedback/index.vue")
  },
  {
    name: "for_customer___en",
    path: "/for_customer",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/for_customer/index.vue")
  },
  {
    name: "for_customer___ru",
    path: "/ru/for_customer",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/for_customer/index.vue")
  },
  {
    name: "for_seller___en",
    path: "/for_seller",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/for_seller/index.vue")
  },
  {
    name: "for_seller___ru",
    path: "/ru/for_seller",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/for_seller/index.vue")
  },
  {
    name: "games___en",
    path: "/games",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/games/index.vue")
  },
  {
    name: "games___ru",
    path: "/ru/games",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/games/index.vue")
  },
  {
    name: "in-development___en",
    path: "/in-development",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/in-development/index.vue")
  },
  {
    name: "in-development___ru",
    path: "/ru/in-development",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/in-development/index.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/index.vue")
  },
  {
    name: "index___ru",
    path: "/ru",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/index.vue")
  },
  {
    name: "payments___en",
    path: "/payments",
    meta: indexOCkhQPLoCDMeta || {},
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/payments/index.vue")
  },
  {
    name: "payments___ru",
    path: "/ru/payments",
    meta: indexOCkhQPLoCDMeta || {},
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/payments/index.vue")
  },
  {
    name: "products-name--id___en",
    path: "/products/:name()--:id()",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/products/[name]--[id].vue")
  },
  {
    name: "products-name--id___ru",
    path: "/ru/products/:name()--:id()",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/products/[name]--[id].vue")
  },
  {
    name: "products___en",
    path: "/products",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/products/index.vue")
  },
  {
    name: "products___ru",
    path: "/ru/products",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/products/index.vue")
  },
  {
    name: "profile-chat___en",
    path: "/profile/chat",
    meta: indexgdeUfWOH1eMeta || {},
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/profile/chat/index.vue")
  },
  {
    name: "profile-chat___ru",
    path: "/ru/profile/chat",
    meta: indexgdeUfWOH1eMeta || {},
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/profile/chat/index.vue")
  },
  {
    name: "profile-favorites___en",
    path: "/profile/favorites",
    meta: indexYyMRaeKzedMeta || {},
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/profile/favorites/index.vue")
  },
  {
    name: "profile-favorites___ru",
    path: "/ru/profile/favorites",
    meta: indexYyMRaeKzedMeta || {},
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/profile/favorites/index.vue")
  },
  {
    name: "profile-history-balance___en",
    path: "/profile/history-balance",
    meta: indexlMVFEClhxSMeta || {},
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/profile/history-balance/index.vue")
  },
  {
    name: "profile-history-balance___ru",
    path: "/ru/profile/history-balance",
    meta: indexlMVFEClhxSMeta || {},
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/profile/history-balance/index.vue")
  },
  {
    name: "profile___en",
    path: "/profile",
    meta: index22SGTWXFR4Meta || {},
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/profile/index.vue")
  },
  {
    name: "profile___ru",
    path: "/ru/profile",
    meta: index22SGTWXFR4Meta || {},
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/profile/index.vue")
  },
  {
    name: "profile-reviews___en",
    path: "/profile/reviews",
    meta: indexeUkhnR6RQQMeta || {},
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/profile/reviews/index.vue")
  },
  {
    name: "profile-reviews___ru",
    path: "/ru/profile/reviews",
    meta: indexeUkhnR6RQQMeta || {},
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/profile/reviews/index.vue")
  },
  {
    name: "profile-settings___en",
    path: "/profile/settings",
    meta: indexPkkF96leP0Meta || {},
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/profile/settings/index.vue")
  },
  {
    name: "profile-settings___ru",
    path: "/ru/profile/settings",
    meta: indexPkkF96leP0Meta || {},
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/profile/settings/index.vue")
  },
  {
    name: "registration___en",
    path: "/registration",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/registration/index.vue")
  },
  {
    name: "registration___ru",
    path: "/ru/registration",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/registration/index.vue")
  },
  {
    name: "request-password___en",
    path: "/request-password",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/request-password/index.vue")
  },
  {
    name: "request-password___ru",
    path: "/ru/request-password",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/request-password/index.vue")
  },
  {
    name: "rules___en",
    path: "/rules",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/rules/index.vue")
  },
  {
    name: "rules___ru",
    path: "/ru/rules",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/rules/index.vue")
  },
  {
    name: "search___en",
    path: "/search",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/search/index.vue")
  },
  {
    name: "search___ru",
    path: "/ru/search",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/search/index.vue")
  },
  {
    name: "services___en",
    path: "/services",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/services/index.vue")
  },
  {
    name: "services___ru",
    path: "/ru/services",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/services/index.vue")
  },
  {
    name: "steam-topup___en",
    path: "/steam-topup",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/steam-topup/index.vue")
  },
  {
    name: "steam-topup___ru",
    path: "/ru/steam-topup",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/steam-topup/index.vue")
  },
  {
    name: "store-create___en",
    path: "/store-create",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/store-create/index.vue")
  },
  {
    name: "store-create___ru",
    path: "/ru/store-create",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/store-create/index.vue")
  },
  {
    name: "stores-name--id___en",
    path: "/stores/:name()--:id()",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/stores/[name]--[id].vue")
  },
  {
    name: "stores-name--id___ru",
    path: "/ru/stores/:name()--:id()",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/stores/[name]--[id].vue")
  },
  {
    name: "stores___en",
    path: "/stores",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/stores/index.vue")
  },
  {
    name: "stores___ru",
    path: "/ru/stores",
    component: () => import("/var/www/gstock.org/gstock-front-20250313060501/pages/stores/index.vue")
  }
]