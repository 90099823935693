import { formatNumberWithSeparators } from "~/assets/functions"

export function useCurrencyHelpers() {
	const { CurrentCurrencyRate, CurrentCurrency } = storeToRefs(useCurrencyStore())
	const getUsdAmount = (amount: number) => {
		console.log(amount, CurrentCurrencyRate.value)
		return Number((amount / CurrentCurrencyRate.value).toPrecision(6))
	}
	const getCurrentCurrencyAmount = (amount: number, _pow: number = 2) => {
		const pow = Math.pow(10, _pow)
		return Math.round(Number(amount) * CurrentCurrencyRate.value * pow) / pow
	}
	const getCurrentCurrencyString = (amount: number) => `${formatNumberWithSeparators(getCurrentCurrencyAmount(amount))} ${CurrentCurrency.value?.symbol ?? CurrentCurrency.value?.code}`
	const getCurrencyString = (amount: number) => `${formatNumberWithSeparators(Math.round(amount * 100) / 100)} ${CurrentCurrency.value?.symbol ?? CurrentCurrency.value?.code}`
	return { getUsdAmount, getCurrentCurrencyAmount, getCurrentCurrencyString, getCurrencyString }
};
