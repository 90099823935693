import type { productData } from "./products/types/productTypes";

export function useFastPayModal() {
	const { add, remove } = useBodyOverflowDependence()
	const currentFastPayProduct = useState<productData | null>('product-fast-pay-popup', () => null);
	const currentFastPayProductCount = useState<number>('product-fast-pay-count', () => 1)
	const isPayModalOpen = useState<boolean>('is-fast-pay-modal-open', () => false);

	function openFastPayPopup(product: productData, count?: number) {
		currentFastPayProduct.value = product;
		if (count){
			currentFastPayProductCount.value = count
		}
		else {
			currentFastPayProductCount.value = 1
		}
		isPayModalOpen.value = true;
		add('pay-fast-Modal')
	}

	function closeFastPayPopup() {
		isPayModalOpen.value = false;
		currentFastPayProduct.value = null;
		remove('pay-fast-Modal')
	}

	return {
		currentFastPayProduct,
		isPayModalOpen,
		currentFastPayProductCount,
		openFastPayPopup,
		closeFastPayPopup
	};
}
