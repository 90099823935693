import type { productPagination, productRequestType, productData } from './types/productTypes';
import type { GstockRange } from '~/types/commons';
export function useProducts() {
    const { $api } = useNuxtApp();  

    const getProducts = async (options?: productRequestType) => {
        const res = await $api<{ products: productPagination }>('/product/all', {
            method: 'GET',
            params: {
                ...options,
            },
        });

        if (res) {
			return res.products
        }
        return null;
    }

    const getProductById = async (body: {exclude?: string, range?: GstockRange}, id: string) => {
        const res = await $api<{product: productData}>(`/product/${id}`, {
            query: {
                ...body
            }
        })
        if (res) {
            return res.product
        }
    }

    return {
		getProducts,
        getProductById
    }
}
