import type { getBackPluginsResponce } from './types/backTypes'

export const useBackPlugins = () => {
	const { $api } = useNuxtApp()
	const getBackPlugins = async () => {
		try {
			return await $api<getBackPluginsResponce>('/plugins')
		} catch (error) {
			return null
		}
	}

	return {
		getBackPlugins
	}
}