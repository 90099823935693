<template>
	<div class="tabs-root">
		<ul class="tabs-header">
			<li v-for="tab in tabs" :key="tab.props?.name" class="tabs-header-item" :class="{ 'active': tab.props?.name == activeTab }" @click="updateActiveTab(tab.props?.name)">
				<slot name="title" v-bind="{ title: tab.props?.title, name: tab.props?.name }">{{ tab.props?.title }}</slot>
			</li>
		</ul>
		<slot name="default" />
	</div>
</template>

<script lang="ts" setup>
const slots = useSlots();
const defaultSlot = slots.default;

const tabs = computed(() => {
	return defaultSlot
		/* @ts-ignore */
		? defaultSlot().filter((child) => {
			return (child.type as { __TAB__?: true }).__TAB__;
		})
		: [];
});

watch(tabs, (newValue, oldValue) => {
	if (newValue.length >= oldValue.length) { return; }
	if (newValue.some((a: any) => a.props?.name !== activeTab.value)) { updateActiveTab(tabs.value.find(() => true)?.props?.name); }
});

const activeTab = defineModel<string>();

function updateActiveTab(name: string) {
	activeTab.value = name;
}

provide(Symbol.for('activeTab'), activeTab);

if (tabs.value.length > 0 && activeTab.value === undefined) { updateActiveTab(tabs.value.find(() => true)?.props?.name); }
</script>
