<template>
    <div class="header__lang  header-currecny-changer12314" id="header-currecny-changer12314" @click.stop="toggleLangList()">
        <img :src="`/icons/currency/${CurrentCurrency?.code}.svg`" class="icon-flag" />
        <span>{{ CurrentCurrency?.code }}</span>
        <img :src="`/icons/arrow.svg`" class="icon-arrow" :class="{ 'rotate-180': showLangList }" />
        <ul class="lang__list" :class="showLangList ? 'active' : ''">
            <template v-for="currency in currencies.filter(currency => currency.code !== CurrentCurrency?.code)">
                <span @click="changeCurrency(currency)">
                    <li class="lang-item">
                        <img :src="`/icons/currency/${currency.code}.svg`" class="icon-flag" />
                        <span>{{ currency.code }}</span>
                    </li>
                </span>
            </template>
        </ul>
    </div>
</template>

<script setup lang="ts">
import type { Currency } from '~/composables/currency/types/currencyTypes';
import { useCurrency } from '~/composables/currency/useCurrency';
const currencyStore = useCurrencyStore();
const { getCurrencyCourse } = useCurrency();
const { currencies, CurrentCurrency } = storeToRefs(currencyStore);

const emits = defineEmits(['changeOpen']);
const changeCurrency = async (currency: Currency) => {
    if (currency.code === 'USDT') {
        currencyStore.setCurrentCurrencyRate(1);
        currencyStore.setCurrentCurrency(currency);
        return
    }
    const rate = await getCurrencyCourse(currency.code);
    if (rate) {
        currencyStore.setCurrentCurrencyRate(rate.rate);
        currencyStore.setCurrentCurrency(currency);
        return
    }
    return
}

const showLangList = ref(false);
function toggleLangList() {
    showLangList.value = !showLangList.value;
}

const clickOutsideHandler = (event: MouseEvent) => {
    //@ts-ignore
    if (event.target?.id !== 'header-currecny-changer12314') {
        showLangList.value = false
    }
}

watch(showLangList, (newVal) => {
    if (newVal === true) {
        console.log(showLangList.value)
        document.addEventListener('click', clickOutsideHandler)
    } else {
        document.removeEventListener('click', clickOutsideHandler)
    }
})
</script>

<style scoped lang="scss">
.icon-flag {
    width: 16px;
    height: 16px;
}

.lang-item {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
}

.icon-arrow {
    transition: all 0.3s ease-in-out;

}

.rotate-180 {
    transform: rotate(180deg);
}

.header__lang {
    display: flex;
    align-items: center;
    padding: 0 4px;
    gap: 4px;
    color: #090630;
    line-height: 1;
    cursor: pointer;
    position: relative;

    .lang__list {
        display: none;
        position: absolute;
        min-width: max-content;
        width: 100%;
        top: 100%;
        z-index: 100;
        left: 0;
        background: var(--white);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        padding: 8px 0;
        margin-top: 8px;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;

        &.active {
            display: block;
        }

        li {
            padding: 4px 4px;
            transition: background .3s ease;

            &:hover {
                background: #f5f5f5;
            }
        }
    }
}
</style>
