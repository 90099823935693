<template>
	<Teleport to="#teleports">
		<LazyPayModal v-if="isPayModalOpen" />
		<LazyPayFastPayModal v-if="isFastPayModalOpen" />
		<LazyPaymentsNomoneyModal v-if="isNomoneyModalOpen"></LazyPaymentsNomoneyModal>
		<LazyPaySuccessModal v-if="isSuccessModalOpen"></LazyPaySuccessModal>
		<LazyPaySteamPayModal v-if="isSteamPayModalOpen"></LazyPaySteamPayModal>
		<LazyModalNotice v-if="isModalNoticeOpen" />
		<LazySellerSuccesfullyRegistredModal v-if="isSuccesfullyRegistredStoreModalOpen" />
		<!-- <LazyWishDeleteModal v-if="isWishDeleteModalOpen"></LazyWishDeleteModal> -->
	</Teleport>
</template>

<script setup lang="ts">
import { useFastPayModal } from '#imports';
const { isPayModalOpen } = usePayModal() 
const { isPayModalOpen: isFastPayModalOpen } = useFastPayModal()
const { isNomoneyModalOpen } = useNomoneyModal()
const {isSuccesfullyRegistredStoreModalOpen} = useSuccessfullyRegistredStoreModal()
const { isSuccessModalOpen } = useSuccessModal()
const { isWishDeleteModalOpen } = useWishDeleteModal();
const { isSteamPayModalOpen } = useSteamPayModal();
const { isModalNoticeOpen } = useModalNotice()
</script>