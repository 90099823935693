type PageStatus = 'idle' | 'page:finish' | 'page:start'
export function usePageStatus() {
	const pageStatus = useState<PageStatus>('pageStatus', () => 'page:finish');
	const setPageStatus = (value: PageStatus) => pageStatus.value = value

	return {
		pageStatus,
		setPageStatus
	};
}
