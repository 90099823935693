import { defineStore } from 'pinia'
import type { UserMeType } from '~/composables/user/types/UserTypes'

export const useUserStore = defineStore('user', {
  state: ()  => ({
    user: {} as UserMeType,
    isLoggedIn: false,
  }),
  actions: {
    setUser(user: UserMeType) {
      this.user = user
    },
    setLoggedIn(value: boolean) {
      this.isLoggedIn = value
    },
    clearUser() {
      this.$reset()
    }
  }
})