import { useCurrency } from "~/composables/currency/useCurrency";

const changeCurrency = async (locale: string) => {
	const currencyStore = useCurrencyStore();
	const { getCurrencyCourse } = useCurrency();
	if (locale === 'ru') {
		const currency = currencyStore.currencies.find(currency => currency.code === 'RUB')
		if (currency) {
			const rate = await getCurrencyCourse(currency.code);
			if (rate) {
				currencyStore.setCurrentCurrencyRate(rate?.rate)
			}
			currencyStore.setCurrentCurrency(currency)
		}
	}
	if (locale === 'en') {
		const currency = currencyStore.currencies.find(currency => currency.code === 'USD')
		if (currency) {
			currencyStore.setCurrentCurrencyRate(1);
			currencyStore.setCurrentCurrency(currency);
		}
	}
}

export default defineNuxtPlugin({
	name: 'changeCurrency',
	dependsOn: ['customFetch', 'initPlugin'],
	parallel: true,
	async setup(nuxtApp) {
		const { $i18n } = useNuxtApp()
		const { locale } = $i18n
		await callOnce(async () => {
			await changeCurrency(locale.value)
		})
	},
	hooks: {
		async 'i18n:localeSwitched'({ newLocale }) {
			await changeCurrency(newLocale)
		},
	}

})