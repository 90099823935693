<template>
    
<FormKitLazyProvider config-file="true" :default-config="false">
<div @click="overlayClick" ref="header" class="header__mid-container">
        <LazyHeaderMobileMenu @click.stop @close="closeMobileMenu" v-if="isOpenMobileMenu && !isCatalogOpen" />
        <div class="header__mid">
            <div class="header__mid_contents container">
                <SearchPopUp @click.stop @close="closeModal" v-if="isModalShow && searchQuery" :query="searchQuery" />
                <NuxtLinkLocale to="/">
                    <img class="logo" width="153px" height="34px" src="~/assets/images/svg/logo.svg" alt="">
                </NuxtLinkLocale>
                <button @click.stop="catalogClick" class="header-account-bar__item catalog" :class="{ active: isCatalogOpen }">
                    {{ $t('header.catalog') }}
                </button>
                <LazyCatalog @close="setIsCatalogOpen(false)" @products-link-click="setIsCatalogOpen(false)" @click.stop v-if="isCatalogOpen" absolute />
                <FormKit id="header-search-input" prefix-icon="true" :delay="400" @click.stop="searchClick" @keyup.enter="navigateTo(localePath(`/search?search=${searchQuery}`))" v-model="searchQuery" :outer-class="{ 'header-search__input': true, 'search-show': isModalShow }" type="text"
                    :placeholder="$t('header.search_placeholder')">
                    <template #prefixIcon="{ classes }">
                        <picture>
                            <source media="(min-width: 1024px)" srcset="assets/images/svg/search.svg" />
                            <source media="(max-width: 1023px)" srcset="assets/images/svg/magnifying-glass.svg" />
                            <img width="24px" height="24px" @click.stop="navigateTo(localePath(`/search?search=${searchQuery}`))" :class="classes.prefixIcon" src="~/assets/images/svg/search.svg" alt="">
                        </picture>
                    </template>
                </FormKit>

                <div class="header-account-bar">
                    <ClientOnly>
                        <template #fallback></template>
                        <NuxtLinkLocale v-if="user.user" to="/profile/history-balance" class="header-account-bar__item balance">
                            <div class="balance-bar">
                                <span>
                                    {{ user.user.balance ? formatNumberWithSeparators(Math.round(user.user.balance * CurrentCurrencyRate * 100) / 100) + ' ' + (CurrentCurrency?.symbol ? CurrentCurrency?.symbol : CurrentCurrency?.code)
                                        : `0 ${CurrentCurrency?.symbol ? CurrentCurrency?.symbol : CurrentCurrency?.code}` }}
                                </span>
                                <span v-if="user.user.awaiting_confirmation" class="counts">{{ user.user.awaiting_confirmation }}</span>
                            </div>
                            <span>{{ $t('header.balance') }}</span>
                        </NuxtLinkLocale>
                        <NuxtLinkLocale v-if="isLoggedIn" to="/profile/favorites" class="header-account-bar__item favourites">
                            <img src="~/assets/images/svg/heart.svg" alt="">
                            <span>{{ $t('header.favourites') }}</span>
                            <div
                              v-if="false"
                              class="false"
                            >
                              <span
                                  v-if="wish_ids.length"
                                  class="counts"
                              >
                                {{ wish_ids.length }}
                              </span>
                            </div>
                        </NuxtLinkLocale>
                    </ClientOnly>
                    <div @click.stop="mobileOpenSearch" class="header-account-bar__item search">
                        <img src="~/assets/images/svg/magnifying-glass.svg" alt="">
                        <span>{{ $t('header.search') }}</span>
                    </div>
                    <ClientOnly>
                        <template #fallback>
                            <NuxtLinkLocale :to="'/auth'">
                                <div class="header-account-bar__item">
                                    <img src="~/assets/images/svg/user.svg" alt="">
                                    <span>{{ $t('header.login') }}</span>
                                </div>
                            </NuxtLinkLocale>
                        </template>
                        <NuxtLinkLocale v-if="!isLoggedIn" :to="'/auth'">
                            <div class="header-account-bar__item">
                                <img src="~/assets/images/svg/user.svg" alt="">
                                <span>{{ $t('header.login') }}</span>
                            </div>
                        </NuxtLinkLocale>
                        <div @click.stop class="profile-mobile-wrapper" v-if="isLoggedIn">
                            <div class="header-account-bar__item" @click.stop="profileMenuOpen">
                                <NuxtImgC v-if="!isProfileMenuOpen" class="header-account-bar__item-profile-img" :src="user.user.avatar" :fallback="useAsset('/assets/images/svg/user.svg')" alt="" />
                                <img v-else src="~/assets/images/svg/x-round.svg" alt="">
                                <span :class="{ 'header-account-bar__item-max-w': user.user.nickname ? user.user.nickname.length > 12 : user.user.name.length > 12 }">{{ user.user.nickname?.substring(0, 11) || user.user.name?.substring(0, 11) || $t('header.profile') }}</span>
                            </div>
                            <div v-if="isProfileMenuOpen" class="profile-mobile-links">
                                <div class="profile-info" v-if="user.user">
                                    <div class="info-wrapper">
                                        <ProfileAvatar />
                                        <div class="text-box">
                                            <p class="name">{{ user.user.name }}</p>
                                            <p class="details">ID: {{ user.user.id }}</p>
                                            <p class="details">{{ user.user.email }}</p>
                                        </div>
                                    </div>
                                </div>
                                <NuxtLinkLocale
                                    v-for="(item, i) in menu"
                                    :key="i"
                                    class="item"
                                    :to="item.slug"
                                    @click="handleProfileMenuClick(item.slug)"
                                >
                                    <img class="sidebar-icon" :src="item.icon" alt="">
                                    {{ item.label }}
                                </NuxtLinkLocale>
                                <span class="item item-gray" @click="logout">
                                    <img class="sidebar-icon" src="~/assets/images/svg/profile/door.svg" alt="">
                                    {{ $t('profile.logout') }}
                                </span>
                            </div>
                        </div>
                    </ClientOnly>
                    <div @click.stop="menuClick" class="header-account-bar__item menu">
                        <img v-if="!isOpenMobileMenu" src="~/assets/images/svg/circles-four.svg" alt="">

                        <img v-else src="~/assets/images/svg/x-round.svg" alt="">
                        <span>{{ $t('header.menu') }}</span>
                    </div>
                    <!-- KILLME - удалить если через месяц не будет нужно 21012025 -->
                    <!-- <NuxtLinkLocale v-if="isLoggedIn" to="/profile/chat" class="header-account-bar__item chats">
                        <img src="assets/images/svg/сhats.svg" alt="">
                        <span>{{ $t('header.chats') }}</span>
                    </NuxtLinkLocale> -->
                </div>
            </div>
        </div>
    </div>
    <div v-if="(isOpenMobileMenu && !isCatalogOpen) || isCatalogOpen || isModalShow || isProfileMenuOpen" @click="overlayClick" class="header-overlay"></div>
</FormKitLazyProvider>

</template>
<script lang='ts' setup>
import { FormKitLazyProvider } from '@formkit/vue'
import { useUserStore } from '#imports';
import { formatNumberWithSeparators } from '~/assets/functions';
import { useUserAuth } from '~/composables/user/UseUserAuth';
import { useBackLink } from '~/composables/useBackLink';
const searchQuery = ref()
const productStore = useproductstore();
const userStore = useUserStore();
const catalogStore = useCatalogStore()
const currencyStore = useCurrencyStore();
const header = ref();
const { wish_ids } = storeToRefs(productStore);
const { CurrentCurrencyRate, CurrentCurrency } = storeToRefs(currencyStore);
const { user, isLoggedIn } = storeToRefs(userStore)
const { bottom } = useElementBounding(header);
const headerBottomPosition = useState('header-bottom-position');
const { isCatalogOpen } = storeToRefs(catalogStore)
const { setIsCatalogOpen } = useCatalogStore()
const { closeModal, isModalShow, openModal } = useModal()
const { closeModal: closeMobileMenu, isModalShow: isOpenMobileMenu, openModal: openMobileMenu } = useModal('mobileMenu')
const localePath = useLocalePath()
const { t } = useI18n()
const { userLogout } = useUserAuth()
const { setBackLink } = useBackLink()

const isProfileMenuOpen = ref(false)

const menu = computed(() => [
    {
        slug: '/profile',
        label: t('profile.orders'),
        icon: useAsset('/assets/images/svg/profile/shopping-bag-open.svg')
    },
    {
        slug: '/profile/history-balance',
        label: t('profile.balance'),
        icon: useAsset('/assets/images/svg/profile/wallet.svg')
    },
    {
        slug: '/profile/reviews',
        label: t('profile.reviews'),
        icon: useAsset('/assets/images/svg/profile/chat-circle-text.svg')
    },
    {
        slug: '/profile/favorites',
        label: t('profile.wishlist'),
        icon: useAsset('/assets/images/svg/profile/heart-straight.svg')
    },
    {
        slug: '/profile/settings',
        label: t('profile.settings-title'),
        icon: useAsset('/assets/images/svg/profile/gear.svg')
    },
    {
        slug: '/profile/chat',
        label: t('profile.chats'),
        icon: useAsset('/assets/images/svg/profile/chats.svg')
    },
])

const logout = async () => {
    isProfileMenuOpen.value = false;
    await navigateTo(localePath('/'))
    await userLogout().then(() => { navigateTo(localePath('/')) });
}

const catalogClick = () => {
    closeMobileMenu()
    closeModal();
    setIsCatalogOpen(!isCatalogOpen.value)
    isProfileMenuOpen.value = false;
}

const searchClick = () => {
    closeMobileMenu()
    setIsCatalogOpen(false)
    openModal()
    isProfileMenuOpen.value = false;
}

const overlayClick = () => {
    closeMobileMenu()
    closeModal();
    setIsCatalogOpen(false)
    isProfileMenuOpen.value = false
}

const menuClick = () => {
    closeModal();
    setIsCatalogOpen(false)
    isOpenMobileMenu.value ? closeMobileMenu() : openMobileMenu()
    isProfileMenuOpen.value = false;
}

watchEffect(() => {
    headerBottomPosition.value = bottom.value
})

const route = useRoute();
watch(() => route.path, overlayClick)

useHead({
    link: [{
        rel: 'preload',
        as: 'image',
        href: useAsset('/assets/images/svg/logo.svg')
    }]
})

const mobileOpenSearch = () => {
    closeMobileMenu()
    setIsCatalogOpen(false)
    openModal()
    isProfileMenuOpen.value = false;
    nextTick(() => document.querySelector<HTMLElement>('#header-search-input')?.focus())
}

const profileMenuOpen = () => {
    closeMobileMenu()
    closeModal();
    setIsCatalogOpen(false)
    isProfileMenuOpen.value = !isProfileMenuOpen.value;
}

const handleProfileMenuClick = (path: string) => {
    setBackLink(route.path)
    isProfileMenuOpen.value = false
}
</script>

<style lang="scss" scoped>
.header__mid-container {
    z-index: 10;
    position: sticky;
    top: 8px;
    margin: 0 8px;
}

.header-overlay {
    z-index: 9;
    position: fixed;
    inset: 0;
}

.header__mid {
    padding: 11px 14px;
    background-color: #090630;
    border-radius: 17px;
    position: relative;
}

.header__mid_contents {
    display: flex;
    align-items: center;
    gap: 36px;
    position: relative;

    a {
        flex-shrink: 0;
    }
}

.logo {
    width: 153px;
    height: 34px;

    &:hover {
        opacity: 0.8;
    }
}

.header-search__input {
    flex-grow: 1;

    :deep(.formkit-input) {
        height: 56px;
        font-size: 20px;
        border-radius: 14px;
        font-weight: 400;
        width: 0;

        &::placeholder {
            color: #090630;
            opacity: 40%;
            font-weight: 400;
        }
    }

    :deep(.formkit-inner) {
        border-radius: 14px;
        background-color: #E6E6EA;

        &:focus-within {
            border-color: #da4727;
        }
    }
}

.header-account-bar {
    display: flex;
    gap: 17px;
}

.header-account-bar__item-profile-img {
    border-radius: 100%;
    object-fit: cover;
    object-position: center;

}

.header-account-bar__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    color: var(--white);
    cursor: pointer;
    justify-content: space-between;
    transition: filter 0.3s ease;
    position: relative;

    &:hover {
        filter: brightness(80%);
    }

    img {
        width: 32px;
        height: 32px;
    }


    .counts {
        content: '';
        position: absolute;
        border-radius: 24px;
        padding: 3px 4px;
        background: #db4727;
        top: 4px;
        right: 14px;
        font-weight: 700;
        font-size: 11px;
        line-height: 80%;
        letter-spacing: -0.03em;
        color: #fff;
        width: fit-content;
        min-width: 22px;
        text-align: center;
    }

    .balance-bar {
        padding: 6px;
        background-color: #323051;
        border-radius: 4px;
        text-wrap: nowrap;
        line-height: 80%;
        margin-top: 5px;
        position: relative;

        .counts {
            left: calc(100% - 4px);
            top: 0px;
        }
    }

    &.search,
    &.menu {
        display: none;
    }

    &.catalog {
        margin-left: -24px;
        margin-right: -28px;
        flex-direction: row;
        gap: 12px;
        padding: 14px 32px;
        font-weight: 700;
        font-size: 24px;
        line-height: 80%;
        letter-spacing: -0.03em;
        border-radius: 14px;
        background: linear-gradient(180deg, #fd6240 0%, #da4727 100%);
        border: 1px solid transparent;
        background-image: linear-gradient(180deg, #fd6240 0%, #da4727 100%), linear-gradient(180deg, rgba(255, 255, 255, .4) 0%, transparent 100%), linear-gradient(180deg, #fd6240 0%, #da4727 100%);
        background-origin: border-box;
        background-clip: padding-box, border-box, border-box;
        color: white;

        &::before {
            content: '';
            background-image: url('assets/images/svg/rectangles.svg');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            width: 28px;
            height: 28px;
        }

        &.active::before {
            content: '';
            background-image: url('assets/images/svg/rounded-x.svg');
            width: 24px;
            height: 24px;
            margin: 2px;
        }

        &:hover {
            background-image: linear-gradient(180deg, #ff7c60 0%, #ff5935 100%), linear-gradient(180deg, rgba(255, 255, 255, .4) 0%, transparent 100%), linear-gradient(180deg, #ff7c60 0%, #ff5935 100%);
            filter: none;
        }

        &:active {
            box-shadow: inset 0 6px 12px 0 rgba(0, 0, 0, 0.5);
            background-image: linear-gradient(180deg, #ec4a26 0%, #c83c1d 100%), linear-gradient(180deg, rgba(255, 255, 255, .2) 0%, transparent 100%), linear-gradient(180deg, #ec4a26 0%, #c83c1d 100%);
        }
    }

    &.chats,
    &.profile-link {
        display: none;
    }
}

@media (max-width: 1023px) {

    .header-search__input {
        display: none;

        &.search-show {
            display: block;
            position: absolute;
            inset: 0;
            height: 100%;
            width: 100%;
            z-index: 1;

            :deep(.formkit-wrapper) {
                height: 100%;
            }

            :deep(.formkit-inner) {
                background: transparent;
                border: none;

                .formkit-input {
                    color: white;
                    height: 100%;
                    background: #090630;
                    border-radius: 12px;
                }

                .formkit-input::placeholder {
                    color: white;
                }
            }
        }
    }

    .header__mid_contents {
        justify-content: space-between;
        gap: 8px;
        position: static
    }

    .header__mid {
        padding: 10px 14px 8px;
        border-radius: 13px;
    }

    .header-account-bar {
        gap: 8px;
    }

    .header-account-bar__item {
        min-width: 37px;
        font-size: 10px;
        line-height: 80%;
        color: rgba(255, 255, 255, 0.6);
        gap: 3px;

        img {
            width: 24px;
            height: 24px;
        }

        &.balance,
        &.favourites,
        &.chats {
            display: none;
        }

        &.search,
        &.menu {
            display: flex;
        }

        &.catalog {
            display: none;
        }
    }

    .logo {
        width: 111px;
        height: 25px;
    }
}

.profile-mobile-wrapper {
    position: static;

    @media (max-width: 1023px) {
        display: block;
    }
}

.header-account-bar__item-max-w {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        inset: 0;
        background: linear-gradient(270deg, #090630, transparent);
    }
}

.profile-mobile-links {
    position: absolute;
    top: 120%;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    background: white;
    border-radius: 0 0 13px 13px;
    padding: 8px;
    z-index: 1000;
    transform-origin: top;
    animation: slideDown 0.2s ease-out;
    background: #F4F4F6;

    @media (min-width: 1024px) {
        width: 344px;
        left: auto;
        right: 0;
    }

    .profile-info {
        padding: 12px;
        border-bottom: 1px solid #d9d9dc;
        margin-bottom: 8px;
        background: white;
        border-radius: 8px;

        .info-wrapper {
            display: flex;
            gap: 12px;
            align-items: center;
            margin-bottom: 12px;

            .avatar {
                width: 48px;
                height: 48px;
            }

            .text-box {
                .name {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 120%;
                    color: #1d254a;
                    margin-bottom: 4px;
                }

                .details {
                    font-size: 13px;
                    line-height: 120%;
                    color: rgba(29, 37, 74, 0.5);
                }
            }
        }
    }

    .item {
        display: flex;
        align-items: center;
        padding: 12px;
        gap: 8px;
        font-weight: 600;
        font-size: 16px;
        line-height: 80%;
        letter-spacing: -0.03em;
        color: #1d254a;
        border-radius: 8px;

        &:hover {
            cursor: pointer;
            background: rgba(0, 0, 0, 0.05);
        }

        &-gray {
            color: rgba(29, 37, 74, 0.5);
        }

        .sidebar-icon {
            width: 24px;
            height: 24px;
        }
    }
}

@keyframes slideDown {
    from {
        transform: scaleY(0);
        opacity: 0;
    }

    to {
        transform: scaleY(1);
        opacity: 1;
    }
}
</style>
