export default defineNuxtPlugin({
	name: 'bodyOverflowDependencePlugin',
	parallel: true,
	async setup(nuxtApp) {
		const { bodyOverflowDependence } = useBodyOverflowDependence()
		watchEffect(() => {
			if (document) {
				if (bodyOverflowDependence.value.size == 0) return document.body.style.overflow = ''
				return document.body.style.overflow = 'hidden'
			}
		})
	},
})