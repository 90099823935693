import type { GstockImageType } from "~/types/commons";

export function useGstockImages() {
    const { $api } = useNuxtApp();

    const getGstockImageById = async (id: number): Promise<{ image: GstockImageType } | null> => {
        try {
            const res = await $api<{ image: GstockImageType }>(`/image/${id}`, {
                method: 'GET'
            });

            if (res) {
                return res;
            }
            return null;
        } catch (error) {
            console.error('Error fetching Gstock image:', error);
            return null;
        }
    }

    const createImage = async (body: FormData) => {
        try {
            const res = await $api<{
                image: {
                    id: number
                    original_name: string
                    name: string
                    url: string
                }
            }>('/image/create', {
                method: 'POST',
                body
            });

            if (res) {
                return res;
            }
            return null;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    return {
        getGstockImageById,
        createImage
    }
}
