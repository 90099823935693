<template>
	<NuxtLinkLocale @click="ecommercePushHandle" class="search-product" :to="`/products/${product.slug}`">
		<div class="img-box">
			<NuxtImgC :src="product.image_id" alt="" :fallback="fallbackImg" sizes="234px lg:87px" />
		</div>
		<div class="text-box">
			<p class="title">{{ product.name }}</p>
			<p class="info">
				<span>{{ $t('search.count') }} {{ product.quantity }}</span>
				<span class="slash"> / </span>
				<span>{{ $t('search.price') }} {{ getCurrentCurrencyString(product.discount_price ?? product.price) }} </span>
			</p>
		</div>
	</NuxtLinkLocale>
</template>

<script setup lang="ts">
import type { productData } from '~/composables/products/types/productTypes';

const props = defineProps<{ product: productData }>()

const { getCurrentCurrencyString } = useCurrencyHelpers()

const { locale } = useI18n()

const fallbackImg = useAsset('/assets/images/png/default-imgs/product.png')
const { ecommercePush } = useYm()
const ecommercePushHandle = () => {
	ecommercePush('click', {
		products: [
			{
				"id": props.product.id,
				"name": props.product.name,
				"price": props.product.discount_price ?? props.product.price,
				"category": props.product.category.name,
			}
		]
	})
}
</script>

<style lang="scss" scoped>
.search-product {
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 16px;
	box-shadow: 0 4px 6px 0 rgba(77, 24, 13, 0.1);
	display: flex;
	overflow: hidden;
	min-height: 86px;

	&:hover {
		background: #fae4df;
		border-color: #f4c4ba;

		.img-box {
			border-color: #f4c4ba;
		}
	}
}

.img-box {
	width: 88px;
	border-right: 1px solid #e6e6e6;
	background: #f1f1f4;
	flex-shrink: 0;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
}

.text-box {
	padding: 14px 22px 11px 16px;
	display: flex;
	flex-direction: column;
	gap: 8px;
	justify-content: space-between;

	font-weight: 600;
	font-size: 16px;
	line-height: 80%;
	letter-spacing: -0.03em;
	color: #da4727;

	.title {

		font-size: 18px;
		line-height: 110%;
		color: #1d254a;
	}
}

@media (max-width:1023px) {
	.search-product {
		flex-direction: column;
	}

	.img-box {
		width: 100%;
		border-right: none;
		border-bottom: 1px solid #e6e6e6;

		img {
			aspect-ratio: 84 / 55;
			max-height: 180px;
		}
	}

	.text-box {
		padding: 10px 12px;
		font-size: 13px;
		line-height: 120%;
		flex-grow: 1;

		.title {
			font-size: 15px;
			line-height: 115%;
		}

		.info {
			display: flex;
			flex-direction: column;

			span {
				display: block;
			}

			.slash {
				display: none;
			}
		}
	}

}
</style>