import type { ServerResponseCommonType } from '~/types/commons';
import { useUserStore } from '~/stores/user';
import type { UserLoginRequestType, UserAuthNewPasswordRequest, UserLoginResponceType, UserMeType, UserRegisterRequestType, UserRegisterResponceType, UserUpdateOptions, } from './types/UserTypes'
type TransportUserAuthDataType = {
    userLogin: (options: UserLoginRequestType) => Promise<UserLoginResponceType | null>
    userRegistration: (options: UserRegisterRequestType) => Promise<UserRegisterResponceType | null>
    getUserMe: () => Promise<UserMeType | null>,
    userLogout: () => Promise<null>,
    userSendForgotPasswordRequest: (body: { email: string }) => Promise<true | null>,
    userResetPassword: (options: UserAuthNewPasswordRequest) => Promise<true | null>,
    userUpdate: (options: UserUpdateOptions) => Promise<UserMeType | null>,
    checkIfEmailExists: (email: string) => Promise<boolean>,
}


export function useUserAuth(): TransportUserAuthDataType {
    const authToken = useCookie('authToken', {
        maxAge: (60 * 60 * 24 * 30 * 12),
        path: '/'
    });
    const { $api } = useNuxtApp();
    const isCaptchaEnabled = useRuntimeConfig().public.isCaptchaEnabled
    const store = useUserStore()


    const userLogin = async (options: UserLoginRequestType): Promise<UserLoginResponceType | null> => {
        //@ts-ignore
        let body = {
            email: options.email,
            password: options.password,
        } as UserLoginRequestType
        if (isCaptchaEnabled) {
            body = {
                ...body,
                'g-recaptcha-response': options['g-recaptcha-response']
            }
        }
        const res = await $api<UserLoginResponceType>('/auth/login', {
            method: 'POST',
            body: {
                ...body
            }
        })
        if (res) {
            authToken.value = res.token
            return res
        }
        return null;

    }

    const userRegistration = async (options: UserRegisterRequestType): Promise<UserRegisterResponceType | null> => {
        const res = await $api<ServerResponseCommonType<UserRegisterResponceType>>('/auth/register',
            { method: 'POST', body: { ...options } }
        )
        if (res && res.data) {
            return res.data
        }
        return null
    }

    const getUserMe = async (): Promise<UserMeType | null> => {
        try {
            const res = await $api<UserMeType>('/auth/me', {
                method: 'GET'
            });
            if (res && res.user && res.user.email) {
                store.setUser(res)
                store.setLoggedIn(true)
                return res;

            }
            return null;
        } catch (error) {
            console.error('Error fetching user data:', error);
            return null;
        }
    }

    const userLogout = async () => {
        await $api('/auth/logout').then(() => {
            
            store.clearUser()
            authToken.value = ''
        })  
        return null
    }

    const userSendForgotPasswordRequest = async (body: { email: string }) => {
        const res = await $api('/auth/forgot-password', {
            method: 'POST',
            body: { ...body }
        })
        //@ts-ignore
        if (res && res.success) {
            return true
        }
        return null
    }

    const userResetPassword = async (options: UserAuthNewPasswordRequest) => {
        const res = await $api('/auth/confirm-password', {
            method: 'POST',
            body: { ...options }
        })
        //@ts-ignore
        if (res && res.success) {
            return true
        }
        return null
    }

    const userUpdate = async (options: UserUpdateOptions) => {
        try {
            return await $api<UserMeType>('/auth/update', {
                method: 'PUT',
                body: { ...options }
            })
        } catch (error) {
            console.error('Error fetching major categories:', error);
            return null;
        }
    }

    const checkIfEmailExists = async (email: string): Promise<boolean> => {
        try {
            const res = await $api<{check_email: boolean}>('/auth/check-email', {
                method: 'POST',
                body: { email }
            });
            return res?.check_email || false;
        } catch (error) {
            console.error('Error checking email:', error);
            return false;
        }
    }

    return {
        userLogin,
        userRegistration,
        getUserMe,
        userLogout,
        userSendForgotPasswordRequest,
        userResetPassword,
        userUpdate,
        checkIfEmailExists
    }

}