<template>
	<NuxtLinkLocale class="search-store" :to="`/stores/${shop.slug}`">
		<div class="img-box">
			<NuxtImgC :src="shop.image_id" alt="" :fallback="fallbackImg" sizes="210px lg:94px"/>
		</div>
		<div class="text-box">
			<div class="extra-info">
				<p class="title">{{ shop.name }}</p>
				<AtomsRating :rating="shop.total_rating" />
				<p class="date">{{ useDateFormat(shop.created_at, 'DD.MM.YYYY') }}</p>
			</div>
			<div class="description" v-html="shop.description"></div>
		</div>
	</NuxtLinkLocale>
</template>

<script setup lang="ts">
import type { ShopData } from '~/composables/shops/types/ShopTypes';

defineProps<{ shop: ShopData }>()

const { locale } = useI18n();

const fallbackImg = useAsset('/assets/images/png/default-imgs/store.png')
</script>

<style lang="scss" scoped>
.search-store {
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 16px;
	box-shadow: 0 4px 6px 0 rgba(77, 24, 13, 0.1);
	display: flex;
	overflow: hidden;
	min-height: 100px;

	&:hover {
		background: #fae4df;
		border-color: #f4c4ba;

		.img-box {
			border-color: #f4c4ba;
		}
	}
}

.img-box {
	width: 95px;
	border-right: 1px solid #e6e6e6;
	flex-shrink: 0;

	img {
		background: #f1f1f4;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
}

.text-box {
	display: grid;
	grid-template-columns: 1fr 2fr;
	padding: 13px 16px 15px;
	gap: 8px;
	justify-content: space-between;
	width: 100%;

	font-weight: 400;
	font-size: 16px;
	line-height: 120%;
	letter-spacing: -0.03em;
	color: #1d254a;

	.title {
		font-weight: 600;
		font-size: 18px;
		line-height: 110%;
		margin-bottom: 7px;
	}

	.date {
		font-weight: 600;
		font-size: 14px;
		line-height: 80%;
		color: #da4727;
		margin-top: 10px;
	}

	.description{
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 4;
		padding-top:3px;
		margin-top:-3px;
	}
}

@media (max-width:1023px) {
	.search-store {
		flex-direction: column;

		&:hover {
			background: #fae4df;
			border-color: #f4c4ba;

			.img-box {
				border-color: #f4c4ba;
			}
		}
	}

	.img-box {
		width: 100%;
		padding: 7px;
		border-right: none;

		img {
			border-radius: 12px;
			aspect-ratio: 77 / 50;
			height: auto;
			max-height: 180px;
		}
	}

	.text-box {
		grid-template-columns: 1fr;
		padding: 5px 10px 10px;

		font-size: 12px;

		.title {
			font-weight: 700;
			font-size: 16px;
			margin-bottom: 8px;
		}

		.date {
			font-size: 12px;
		}
	}

}
</style>