
export function formatNumberWithSeparators(input?: string | number): string {
    if (!input) {
        return ''
    }
    // Преобразуем вход в строку
    const stringInput = input.toString();

    // Проверяем, является ли строка числом
    if (/^\d+$/.test(stringInput) || /^\d+\.\d+$/.test(stringInput)) {
        // Разделяем целую и дробную части
        const [integerPart, fractionalPart] = stringInput.split('.');

        // Форматируем целую часть, добавляя разделители
        const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

        // Возвращаем отформатированное число, добавляя дробную часть, если она есть
        return fractionalPart ? `${formattedInteger},${fractionalPart}` : formattedInteger;
    }

    // Если вход не является числом, возвращаем исходную строку
    return stringInput;
}


export function formatErrorMessages(error: any): string {
    if (!error?.data) {
        return '';
    }

    const messages: string[] = [];

    // Add main message if exists
    if (error.data.message) {
        messages.push(error.data.message);
    }

    // Process nested data object if exists
    if (error.data.data) {
        Object.values(error.data.data).forEach((errors: any) => {
            if (Array.isArray(errors)) {
                messages.push(...errors);
            }
        });
    }

    return messages.join('. ');
}

export const detectDeviceAndRedirect = (url: string) => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isIOS = /iphone|ipad|ipod/.test(userAgent);
    if (isIOS) {
        window.location.href = url
        return
    }
    else {
        navigateTo(url, { external: true, open: { target: '_blank' } })

    }
}  