import type { RouterConfig } from '@nuxt/schema';
// https://router.vuejs.org/api/#routeroptions
export default <RouterConfig>{
	scrollBehaviorType: 'smooth',
	scrollBehavior: (to, from, savedPosition) => {
		const { setPageStatus, pageStatus } = usePageStatus()

		// scroll to hash, useful for using to="#some-id" in NuxtLink
		// ex: <NuxtLink to="#top"> To Top </ NuxtLink>
		if (to.hash) {
			return {
				el: to.hash,
				top: _getHashElementScrollMarginTop(to.hash),
				behavior: 'smooth'
			};
		}

		// The remainder is optional but maybe useful as well
		// if link is to same page, scroll to top with smooth behavior
		if (to === from) {
			return {
				left: 0,
				top: 0,
				behavior: 'smooth'
			};
		}

		const scrollOptions = new Promise((resolve) => {
			const unwatch = watch(pageStatus, () => {
				if (pageStatus.value === 'page:finish') {
					unwatch()
					return resolve({
						left: savedPosition?.left ?? 0,
						top: savedPosition?.top ?? 0,
					})
				}
			})
		})

		setPageStatus('idle')
		return scrollOptions
	}
};

function _getHashElementScrollMarginTop(selector: string) {
	try {
		const elem = document.querySelector(selector)
		if (elem) {
			return (Number.parseFloat(getComputedStyle(elem).scrollMarginTop) || 0) + (Number.parseFloat(getComputedStyle(document.documentElement).scrollPaddingTop) || 0)
		}
	} catch {
		// ignore any errors parsing scrollMarginTop
	}
	return 0
}
