<template>
  <div
    ref="root"
    class="toast-root"
  >
    <div
      class="toast-component"
      :class="classListComponent"
    >
      <div
        class="toast-component__content"
        :class="classListContent"
      >
        <div
          class="toast-component__title"
          v-html="title" 
        />
        <div
          class="toast-component__message"
          v-html="message"
        />
      </div>
    </div>
    <div
      v-if="classListComponent === 'center'"
      class="toast-overlay"
      @click="closeAllToast" 
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, render } from 'vue';
import { removeElement } from '~/plugins/toast';

import type { IToastPosition, IToastTypes } from '~/plugins/toast';

const {
  message = '',
  title = '',
  position = 'bottom',
  type = 'success',
  duration = 3000
} = defineProps<{
	message: string;
	type: IToastTypes;
	position?: IToastPosition;
	duration?: number;
	title?: string;
}>();

const classListComponent = computed(() => [position].filter((t) => !!t).join(' '));
const classListContent = computed(() => [type].filter((t) => !!t).join(' '));
const root = ref<Element | ShadowRoot | null>(null);
const openToast = () => {
  setTimeout(() => {
    closeAllToast();
  }, duration);
};

const closeAllToast = () => {
  if (root.value !== null) {
    render(null, root.value);
    removeElement(root.value);
  }
};

onMounted(() => {
  openToast();
});

</script>

<style lang="scss">
.toast-root {
	position: relative;
}

.toast-component {
	position: fixed;
	z-index: var(--toast-main-z-index);

	&.center {
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		}

	&.top {
		top: 20px;
		left: 50%;
		transform: translateX(-50px);
	}

	&.top-right {
		top: 20px;
		right: 20px;
	}

	&.top-left {
		top: 20px;
		left: 20px;
	}

	&.bottom {
		bottom: 64px;
		left: 50%;
		transform: translateX(-50%);
		padding: 0 8px;		
		width: 100%;
		max-width: 673px;

		@media (max-width:1023px) {
			bottom: 8px;
		}
	}

	&.bottom-right {
		bottom: 20px;
		right: 20px;
	}

	&.bottom-left {
		bottom: 20px;
		left: 20px;
	}
}

.toast-overlay {
	inset: 0;
	background: var(--toast-overlay-bg);
	position: fixed;
	z-index: var(--toast-overlay-z-index);
}

.toast-component__content {
	&.success {
		background-color: var(--toast-success-bg);
		color: var(--toast-success-text);
	}

	&.info {
		background-color: var(--toast-info-bg);
		color: var(--toast-info-text);
	}

	&.error {
		background-color: var(--toast-error-bg);
		color: var(--toast-error-text);
	}

	&.warning {
		background-color: var(--toast-warning-bg);
		color: var(--toast-warning-text);
	}

	&.default {
		background-color: var(--toast-info-bg);
		color: var(--toast-info-text);
	}

	&.pop-up {}
}

.toast-component__content {
	display: flex;
	flex-direction: column;
	gap: 12px;
	padding: 28px 24px 24px;
	box-shadow: 0 4px 4px 0 rgba(9, 73, 31, 0.3);	
	border: 1px solid rgba(255, 255, 255, 0.3);	
	border-radius: 24px;

	@media (max-width:1023px) {
		padding: 24px 24px 18px;
	}
}

@keyframes up {
	0% {
		transform: translate(-50%, -50%);
	}

	95% {
		transform: translateY(999px);
	}
}
</style>
