<template>
	<NuxtLayout name="error">
		<div class="in-development-page container">
			<h2 class="in-development-page-title">{{error?.statusCode}}</h2>
			<p class="in-development-page-text">{{ t(`error.${error?.statusCode}`) }}</p>
			<!-- <p class="in-development-page-text" v-if="error?.statusCode === 500">{{ error }}</p> -->
			<img class="in-development-page-img" src="~/assets/images/png/in-development/image.png" alt="">
			<NuxtLinkLocale to="/" class="home-page-link">{{ t('error.go_home') }}</NuxtLinkLocale>
		</div>
	</NuxtLayout>
</template>

<script setup lang="ts">
const { t } = useI18n()
const error = useError()
console.log(error.value)

</script>

<style lang="scss" scoped>
.in-development-page {
	margin-top: 24px;
}

.in-development-page-title {
	font-weight: 800;
	font-size: 40px;
	line-height: 80%;
	letter-spacing: -0.03em;
	text-align: center;
	color: #1d254a;
	margin-top: 64px;
}

.home-page-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin: 32px auto;
    padding: 12px 24px;
    background: var(--firmennyy);
    border-radius: 12px;
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
    text-decoration: none;
    transition: all 0.3s ease;

    &:hover {
        opacity: 0.8;
    }
}

.in-development-page-text {
	font-weight: 600;
	font-size: 20px;
	line-height: 80%;
	letter-spacing: -0.03em;
	text-align: center;
	color: #858398;
	margin-top: 32px;
}

.in-development-page-img {
	width: 446px;
	height: 446px;
	margin: 32px auto 0;
}

@media (max-width:1023px) {
	.in-development-page {
		margin-top: 24px;
	}

	.in-development-page-title {
		font-size: 28px;
		text-align: left;
		margin-top: 32px;
	}

	.in-development-page-text {
		font-size: 16px;
		line-height: 120%;
		text-align: left;
		margin-top: 12px;
	}

	.in-development-page-img {
		width: 263px;
		height: 263px;
		margin: 12px auto 0;
	}
}
</style>